import { Listbox, Transition } from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/solid/esm/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/solid/esm/ChevronUpIcon';
import cx from 'classnames';
import React, { Fragment } from 'react';
import { SelectOption, SelectOptionProps } from './select-option';

export interface SelectComponentProps {
  options: SelectOptionProps[];
  value?: SelectOptionProps;
  onChange: (option: SelectOptionProps) => void;
  hasError?: boolean;
  placeholder?: string;
}

export const SelectComponent: React.FC<SelectComponentProps> = ({
  options,
  value,
  onChange,
  hasError,
  placeholder,
}) => (
  <Listbox
    value={value?.id}
    onChange={(value) =>
      onChange(options.find((option) => option.id === value))
    }
  >
    {({ open }) => (
      <div className="mt-1 relative">
        <Listbox.Button
          className={cx(
            'relative w-full pl-3 pr-10 py-2 cursor-default min-h-10.5 lg:min-h-12.5',
            'bg-white border border-gray-300 rounded-md shadow-sm',
            'steps-s lg:body-xs  text-center',
            'focus:outline-none focus:ring-1 focus:ring-green focus:border-green',
            value?.value ? 'text-gray-700' : 'text-gray-400',
            {
              'border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500':
                hasError,
            }
          )}
        >
          <span className="block truncate">{value?.value || placeholder}</span>
          <span className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none border-l-2 border-gary-300">
            {open ? (
              <ChevronUpIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            )}
          </span>
        </Listbox.Button>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-30 mt-1 w-full bg-white shadow-lg  max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
            {options.map((option) => (
              <SelectOption key={option.id} {...option} />
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
);
