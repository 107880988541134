import { graphql } from 'gatsby';
import React from 'react';
import { DiseasesBlockList } from '../components/diseases-list/diseases-list';
import { Hero } from '../components/hero/hero';
import Layout from '../components/layout/layout';
import { Testimonials } from '../components/testimonials/testimonials';
import { ScrolledList } from '../components/scrolled-list/scrolled-list';
import { Gallery } from '../components/gallery/gallery';
export const Index = ({ data }) => {
  if (!data.index) return null;

  const { hero, diseasesList, servicesList, testimonials, gallery, seo } =
    data.index;

  return (
    <Layout {...data} seo={seo}>
      {hero && <Hero {...hero} />}
      {diseasesList && <DiseasesBlockList {...diseasesList} />}
      {servicesList && <ScrolledList {...servicesList} />}
      {testimonials && <Testimonials {...testimonials} />}
      {gallery && <Gallery {...gallery} />}
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query IndexPage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    index: strapiIndex(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      hero {
        heading {
          ...HeadingTitleDescriptionFragment
        }
        audio {
          url
          mime
        }
        carousel {
          heading {
            ...HeadingTitleFragment
          }
          cards {
            ...CardFragment
          }
        }
      }
      diseasesList {
        heading {
          ...HeadingAllFragment
        }
        section {
          heading {
            ...HeadingTitleDescriptionFragment
          }
          blocks {
            ...GridBlockFragment
          }
        }
      }
      testimonials {
        heading {
          ...HeadingTitleDescriptionFragment
        }
        testimonials {
          image {
            ...ImageFragment
          }
          text {
            data {
              childMdx {
                body
              }
            }
          }
          name
          description
          date
          url
        }
        link {
          ...ButtonFragment
        }
      }
      servicesList {
        heading {
          ...HeadingAllFragment
        }
        list {
          name
          list {
            title
            text {
              data {
                childMdx {
                  body
                }
              }
            }
            link {
              ...ButtonFragment
            }
          }
        }
      }
      gallery {
        photos {
          ...GridBlockFragment
        }
      }
    }
  }
`;
