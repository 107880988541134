import React, { Context, Dispatch } from 'react';
import { useTranslationsContextData } from '../../hooks/use-context-data';
import { useIsServerClient } from '../../hooks/use-is-server-client';

export const MapContext: Context<{
  isLoading?: boolean;
  map?: any;
  setMap?: Dispatch<any>;
  getDirections?: () => void;
  setCoddedAddress?: Dispatch<any>;
}> = React.createContext({});

export const MapContextProvider = ({ children }) => {
  const { isServer } = useIsServerClient();
  const translations = useTranslationsContextData();

  const [map, setMap] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [coddedAddress, setCoddedAddress] = React.useState(null);

  const { directionsService, directionsRenderer, infoWindow } =
    React.useMemo(() => {
      if (isServer || !window.google) {
        return {};
      }

      return {
        directionsService: new google.maps.DirectionsService(),
        directionsRenderer: new google.maps.DirectionsRenderer({
          map,
        }),
        infoWindow: new google.maps.InfoWindow(),
      };
    }, [isServer, map]);

  const handleLocationError = React.useCallback(
    (position: google.maps.LatLng) => {
      infoWindow.setPosition(position);
      infoWindow.setContent(
        translations['driving-directions.geolocalization-error']
      );
      infoWindow.open(map);
    },
    [isServer, map]
  );

  const getDirections = React.useCallback(() => {
    setIsLoading(true);

    const handleError = () => {
      handleLocationError(coddedAddress);
      setIsLoading(false);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          directionsService
            .route({
              origin: currentPosition,
              destination: coddedAddress,
              travelMode: google.maps.TravelMode.DRIVING,
            })
            .then((response) => {
              directionsRenderer.setDirections(response);
              setIsLoading(false);
            })
            .catch(handleError);
        },
        handleError
      );
    } else {
      handleError();
    }
  }, [isLoading, map, coddedAddress]);

  return (
    <MapContext.Provider
      value={{ isLoading, map, setMap, getDirections, setCoddedAddress }}
    >
      {children}
    </MapContext.Provider>
  );
};
