import React, { TextareaHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';
import { deepFind } from '../../utils/deep-find';
import { Field } from '../field/field';
import { getInputClass } from '../input/input';

export interface TextareaProps
  extends Omit<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    'ref, onChange, onBlur'
  > {
  label?: string;
  containerClassName?: string;
}

export const Textarea: React.FC<TextareaProps> = ({
  label,
  containerClassName,
  className,
  name,
  ...props
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('Textarea must be inside Form Component');
  }
  const {
    register,
    formState: { errors },
  } = ctx;
  const registerProps = register(name);
  const hasError = Boolean(deepFind(errors, name));

  return (
    <Field
      name={name}
      label={label}
      errors={errors}
      className={containerClassName}
    >
      <textarea
        className={getInputClass(
          cx(containerClassName, 'min-h-22'),
          props.disabled,
          hasError
        )}
        {...props}
        {...registerProps}
      />
    </Field>
  );
};
