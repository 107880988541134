import PhoneIcon from '@heroicons/react/outline/esm/PhoneIcon';
import MailIcon from '@heroicons/react/outline/esm/MailIcon';
import React from 'react';
import { MDXProps } from '../../types/shared';

export interface FooterContactProps {
  text: MDXProps;
  label: string;
  type: 'phone' | 'email';
}

export const FooterContact: React.FC<FooterContactProps> = ({
  label,
  text,
  type,
}) => (
  <div className="mb-8 group">
    <p className="footer-l mb-4">{label}</p>
    <div className="flex gap-3 items-center">
      <span className="flex-none">
        {type === 'phone' ? (
          <PhoneIcon
            className="h-7 w-7 text-gray-300 group-hover:text-green"
            aria-hidden="true"
          />
        ) : (
          <MailIcon
            className="h-7 w-7 text-gray-300 group-hover:text-green"
            aria-hidden="true"
          />
        )}
      </span>
      <a
        href={`${type === 'phone' ? 'tel:' : 'mailto:'}${
          text?.data?.childMdx?.rawBody || ''
        }`}
        className="footer-bold group-hover:text-green"
      >
        {text?.data?.childMdx?.rawBody || ''}
      </a>
    </div>
  </div>
);
