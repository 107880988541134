const GATSBY_CONFIG_INTL = {
  languages: [`pl`, `en`],
  defaultLanguage: `pl`,
};

exports.GATSBY_CONFIG_INTL = GATSBY_CONFIG_INTL;

exports.resolveIntlLanguage = (language) => {
  if (!language || typeof language !== 'string') {
    return GATSBY_CONFIG_INTL.defaultLanguage;
  }

  const lowerLanguage = language.toLowerCase();

  if (!GATSBY_CONFIG_INTL.languages.includes(lowerLanguage)) {
    return GATSBY_CONFIG_INTL.defaultLanguage;
  }

  return lowerLanguage;
};
