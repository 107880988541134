import { getIcon } from '../../../utils/getIcon';
import cx from 'classnames';
import React, { useCallback } from 'react';
import { useIsCurrentLocation } from '../../../hooks/use-is-current-location';
import { SecondLevelProps } from '../../../types/menu';
import { ModalFormType } from '../../../types/modal';
import { AnchorLink } from '../../shared/anchor-link';
import { MenuModalContext } from '../menu';

export const SecondLevelItem: React.FC<
  SecondLevelProps & {
    className?: string;
    contentClassName?: string;
  }
> = ({
  description,
  name,
  url,
  modalType,
  icon,
  highlighted,
  className,
  contentClassName,
}) => {
  const { isCurrentLocation } = useIsCurrentLocation();
  const { openModal, setFormType } = React.useContext(MenuModalContext);

  const handleModalType = useCallback(() => {
    setFormType(modalType.contentType as ModalFormType);
    openModal();
  }, [modalType, setFormType, openModal]);

  const isActive = isCurrentLocation(url);
  const Icon = React.useMemo(() => getIcon(icon), [icon]);

  const content = (
    <div className="flex" onClick={modalType ? handleModalType : undefined}>
      {icon && icon !== 'arrow' && (
        <Icon className="h-6 w-6 flex-shrink-0 mr-2.5 text-green" />
      )}
      <div className="flex flex-wrap w-full">
        <p
          className={cx('flex w-full ', {
            'text-gray-800 group-hover:text-green': !isActive,
            'text-green': isActive,
            'text-slate-500': highlighted,
            'text-slate-900': !highlighted && !isActive,
          })}
        >
          {icon === 'arrow' && highlighted ? (
            <span className="flex items-center gap-3 w-full text-green">
              {name}
              <Icon className="h-6 w-6 flex-shrink-0 mr-2.5 text-green" />
            </span>
          ) : (
            name
          )}
        </p>
        {description && <p className="steps-s text-gray-500">{description}</p>}
      </div>
    </div>
  );

  return (
    <li
      className={cx(
        className,
        'menu-links-16 group flex items-center',
        url || modalType ? 'cursor-pointer hover:bg-gray-100' : 'py-3 px-8',
        url || modalType ? '' : contentClassName
      )}
    >
      {modalType ? (
        <div className={cx(contentClassName, 'py-3 px-8')}>{content}</div>
      ) : url ? (
        <AnchorLink
          className={cx(contentClassName, 'py-3 px-8')}
          key={name}
          to={url}
        >
          {content}
        </AnchorLink>
      ) : (
        <span className="flex gap-8 items-center">
          {icon && <Icon className="h-6 w-6 flex-shrink-0 mr-2.5 text-green" />}
          {name}
        </span>
      )}
    </li>
  );
};
