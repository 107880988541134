import React, { ChangeEvent, HTMLProps, useCallback } from 'react';
import CheckboxField from './checkbox-field';

import { useFormContext, Controller } from 'react-hook-form';

export type CheckboxProps = HTMLProps<HTMLInputElement>;

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  onChange,
  ...props
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('Checkbox must be inside Form Component');
  }

  const handleChange = useCallback(
    (handler: React.FormEventHandler<HTMLInputElement>) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        handler(e);
      },
    [onChange]
  );

  return (
    <Controller
      control={ctx.control}
      name={name}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <CheckboxField
          onBlur={onBlur}
          onChange={handleChange(onChange)}
          checked={value || false}
          inputRef={ref}
          name={name}
          {...props}
        />
      )}
    />
  );
};

export default Checkbox;
