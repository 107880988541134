import { useLocation } from '@reach/router';
import React from 'react';
import {
  ModalFormType,
  ModalInfoType,
  ModalTypeProps,
} from '../../../types/modal';
import { PaymentProps } from '../../../types/payment';
import { Button, ButtonProps } from '../../button/button';
import Modal from '../../modal/modal';
import { ModalForm } from '../../modal/modal-types/form/modal-form';
import { ModalInfo } from '../../modal/modal-types/modal-info';
import { ModalPayment } from '../../modal/modal-types/modal-payment';
import { PricingType } from './pricing-box';

interface PricingButtonProps extends ButtonProps {
  className?: string;
  url?: string;
  text: string;
  isExternal?: boolean;
  modalType: ModalTypeProps;
  pricingType: PricingType;
  buttonType: string;
  chosenMember?: PaymentProps['pricingTeam']['0'];
  paymentProps?: PaymentProps;
}

export const PricingButton: React.FC<PricingButtonProps> = ({
  className,
  url,
  text,
  isExternal,
  pricingType,
  buttonType,
  modalType,
  paymentProps,
  chosenMember,
  ...props
}) => {
  const location = useLocation();

  let buttonPrimaryType, buttonProps;
  let ModalContent;

  const title = `${paymentProps?.title}${
    chosenMember ? ` - ${chosenMember.teamMember.name}` : ''
  }`;

  switch (pricingType) {
    case 'pro-bono':
      buttonPrimaryType = 'primary';
      break;
    case 'prescription':
      buttonPrimaryType = 'tertiary';
      break;
    default:
      buttonPrimaryType = 'secondary';
  }

  switch (buttonType) {
    case 'primary':
      buttonProps = { type: buttonPrimaryType, full: true };
      break;
    case 'secondary':
      buttonProps = {
        type: 'outline',
        size: 'sm',
        full: true,
      };
      break;
    case 'additional':
    default:
      buttonProps = {
        type: 'text',
        full: true,
        size: pricingType === 'prescription' ? 'md' : 'sm',
      };
  }

  switch (modalType?.type) {
    case 'payment':
      ModalContent = <ModalPayment paymentProps={{ ...paymentProps, title }} />;
      break;
    case 'form':
      ModalContent = (
        <ModalForm type={modalType?.contentType as ModalFormType} />
      );
      break;
    case 'info':
    default:
      ModalContent = (
        <ModalInfo type={modalType?.contentType as ModalInfoType} />
      );
  }

  if (url && (buttonType === 'primary' || buttonType === 'secondary')) {
    const externalProps = isExternal
      ? { rel: 'noreferrer', target: '_blank' }
      : {};

    return (
      <div className={className}>
        <Button
          href={url}
          tagName="a"
          {...externalProps}
          {...buttonProps}
          {...props}
        >
          {text}
        </Button>
      </div>
    );
  }

  const changeUrl = () => {
    if (paymentProps) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        '',
        `${location.pathname}?price=${paymentProps.price.value}&title=${title}`
      );
    }
  };

  if (modalType) {
    return (
      <div className={className}>
        <Modal
          buttonText={text}
          buttonProps={{
            ...buttonProps,
            onClick: changeUrl,
            disabled:
              !chosenMember &&
              paymentProps?.pricingTeam?.length &&
              modalType?.type === 'payment',
          }}
        >
          {ModalContent}
        </Modal>
      </div>
    );
  }

  return (
    <div className={className}>
      <Button
        {...buttonProps}
        {...props}
        disabled={!chosenMember}
        tagName="a"
        href={url}
      >
        {text}
      </Button>
    </div>
  );
};
