import { Menu, Popover, Transition } from '@headlessui/react';
import XIcon from '@heroicons/react/outline/esm/XIcon';
import ChevronDownIcon from '@heroicons/react/solid/esm/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/solid/esm/ChevronUpIcon';
import cx from 'classnames';
import { useIntl } from 'gatsby-plugin-react-intl';
import React, { Fragment, useCallback } from 'react';
import { ReactComponent as LogoEn } from '../../assets/svg/logo-therapy-center-en-mobile.svg';
import { ReactComponent as LogoPl } from '../../assets/svg/logo-therapy-center-pl-mobile.svg';
import { useIsCurrentLocation } from '../../hooks/use-is-current-location';
import { useIsServerClient } from '../../hooks/use-is-server-client';
import { FirstLevelItem, MenuProps, SecondLevelProps } from '../../types/menu';
import LanguageSelect from '../language-select/language-select';
import { AnchorLink } from '../shared/anchor-link';
import { MenuModalContext } from './menu';
import { getIcon } from '../../utils/getIcon';
import { ModalFormType } from '../../types/modal';

const MobileSecondLevelItem: React.FC<
  SecondLevelProps & { closeMenu: () => void }
> = ({ url, name, modalType, closeMenu, highlighted, icon }) => {
  const { isCurrentLocation } = useIsCurrentLocation();

  const { openModal } = React.useContext(MenuModalContext);
  const isActive = isCurrentLocation(url);

  const Icon = React.useMemo(() => getIcon(icon), [icon]);

  return (
    <>
      {url && (
        <li
          className={cx(
            'w-full py-2 focus:text-green',
            isActive ? 'text-green' : 'text-gray-800',
            {
              italic: highlighted && icon !== 'arrow',
              'pl-3': !highlighted || icon === 'arrow',
            }
          )}
          onClick={closeMenu}
        >
          <AnchorLink to={url}>
            {icon === 'arrow' && highlighted ? (
              <span className="flex items-center gap-3 w-full text-green">
                {name}
                <Icon className="h-6 w-6 flex-shrink-0 mr-2.5 text-green" />
              </span>
            ) : (
              name
            )}
          </AnchorLink>
        </li>
      )}

      {!url && !modalType && <li className="menu-links-16 py-3">{name}</li>}

      {modalType && (
        <li
          className={cx('py-2 w-full focus:text-green', {
            italic: highlighted,
            'pl-3': !highlighted,
          })}
        >
          <button
            onClick={() => {
              openModal();
              closeMenu();
            }}
          >
            {name}
          </button>
        </li>
      )}
    </>
  );
};

const MobileMenuItem: React.FC<FirstLevelItem & { closeMenu: () => void }> = ({
  url,
  closeMenu,
  name,
  additionalLevel,
  secondLevel,
  modalType,
}) => {
  const { isCurrentLocation } = useIsCurrentLocation();
  const isActive = isCurrentLocation(url);
  const [isExpanded, setIsExpanded] = React.useState(false);

  const hideChildren = Boolean(url || modalType);

  const { openModal, setFormType } = React.useContext(MenuModalContext);

  const handleOpenModal = useCallback(() => {
    setFormType(modalType.contentType as ModalFormType);
    openModal();
  }, [modalType, setFormType, openModal]);

  const content = (
    <div
      className={cx(
        'ml-10 menu-links-16 items-center flex w-full group-focus:text-green ',
        {
          'text-green': isActive,
          'text-gray-900': !isActive,
        }
      )}
      onClick={modalType ? handleOpenModal : undefined}
    >
      {name}
    </div>
  );

  return (
    <Menu.Item
      as="li"
      className={cx(
        'group w-full flex-wrap flex items-center px-8 py-3 focus:bg-gray-50 transition-[max-height]',
        isExpanded ? 'max-h-full bg-gray-50' : 'max-h-16 overflow-hidden'
      )}
    >
      {hideChildren ? (
        modalType ? (
          content
        ) : (
          <AnchorLink to={url} onClick={closeMenu} className="flex h-10 w-full">
            {content}
          </AnchorLink>
        )
      ) : (
        <div className="flex w-full cursor-pointer h-10">
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className={cx(
              'menu-links-16 items-center flex w-full group-focus:text-green',
              {
                'text-green': isActive,
                'text-gray-900': !isActive,
              }
            )}
          >
            <div>
              {isExpanded ? (
                <ChevronUpIcon className="h-8 flex-none w-8 text-green mr-2" />
              ) : (
                <ChevronDownIcon className="h-8 flex-none w-8 text-green mr-2" />
              )}
            </div>
            {name}
          </div>
        </div>
      )}

      {!hideChildren && (
        <ul
          className={cx(
            'flex flex-col w-full ml-14',
            isExpanded ? 'block visible' : 'overflow-hidden invisible'
          )}
        >
          {additionalLevel.map((additionalLevelItem, i) => (
            <React.Fragment key={additionalLevelItem.name + i}>
              {additionalLevelItem.name && (
                <li
                  className={cx('menu-links-16 py-3', {
                    'focus:text-green': additionalLevelItem.url,
                  })}
                >
                  {additionalLevelItem.url ? (
                    <AnchorLink to={additionalLevelItem.url}>
                      {additionalLevelItem.name}
                    </AnchorLink>
                  ) : (
                    additionalLevelItem.name
                  )}
                </li>
              )}
              {additionalLevelItem.secondLevel?.map((secondLevel, j) => (
                <MobileSecondLevelItem
                  key={secondLevel.name + j}
                  closeMenu={closeMenu}
                  {...secondLevel}
                />
              ))}
            </React.Fragment>
          ))}

          {secondLevel.map((secondLevelItem, i) => (
            <MobileSecondLevelItem
              key={secondLevelItem.name + i}
              closeMenu={closeMenu}
              {...secondLevelItem}
            />
          ))}
        </ul>
      )}
    </Menu.Item>
  );
};
export const MenuMobile: React.FC<
  MenuProps & {
    isOpen?: boolean;
    closeMenu: () => void;
  }
> = ({ firstLevel, isOpen, closeMenu }) => {
  const { locale } = useIntl();

  const { isClient } = useIsServerClient();

  React.useEffect(() => {
    if (isClient) {
      const rootElements = document.querySelectorAll('body');

      Array.from(rootElements).forEach((element) => {
        element.className = isOpen ? 'overflow-hidden relative' : '';
      });
      return () => {
        Array.from(rootElements).forEach((element) => {
          element.className = '';
        });
      };
    }
  }, [isOpen]);

  return (
    <Transition
      as={Fragment}
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        static
        as="div"
        focus
        className="absolute z-30 top-0 inset-x-0 transition transform origin-top-right xl:hidden"
      >
        <div className="bg-white sticky">
          <div className="py-6 px-3 flex items-center justify-between relative bg-gray-200 bg-opacity-30">
            <div>
              {locale === 'en' ? (
                <LogoEn key="mobile-logo" className="h-12 w-auto" />
              ) : (
                <LogoPl key="mobile-logo" className="h-12 w-auto" />
              )}
            </div>

            <div className="flex items-center">
              <LanguageSelect />
              <button
                className="rounded-md p-2 inline-flex items-center justify-center border-2 border-gray-800 text-gray-800 focus:text-gray-800 focus:bg-gray-100 ml-2 sm:ml-8"
                onClick={closeMenu}
              >
                <span className="sr-only">Close menu</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="overflow-auto h-mobile-content max-h-webkit-fill-available">
          <div className="bg-white h-full overflow-auto">
            <Menu>
              <Menu.Items static as="ul" className="py-6">
                {firstLevel.map((item, i) => (
                  <MobileMenuItem
                    closeMenu={closeMenu}
                    {...item}
                    key={item.name + i}
                  />
                ))}
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  );
};
