import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';

const CanonicalLink = () => {
  const { pathname, hash, search } = useLocation();

  return (
    <Helmet>
      {pathname.length && (hash.length || search.length) ? (
        <meta name="robots" content="noindex"></meta>
      ) : (
        <link
          rel="canonical"
          href={`${process.env.GATSBY_APP_URL}${pathname}`}
        />
      )}
    </Helmet>
  );
};

export default CanonicalLink;
