import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { BlogEntryProps } from '../../types/blog';
import { AnchorLink } from '../shared/anchor-link';
import { MDXComponent } from '../shared/mdx-component';
import TableOfContents from '../table-of-contents/table-of-contents';

const BlogEntryComponent: React.FC<BlogEntryProps> = ({
  category,
  title,
  image,
  content,
  tableOfContents,
}) => (
  <div className="max-w-4xl mx-auto px-6 lg:px-0">
    <div className="flex text-center items-center flex-col pb-8">
      <AnchorLink
        to={`/${category.pathname}/${category.url}`}
        className="h500 text-green uppercase hover:underline"
      >
        {category.name}
      </AnchorLink>
      <h1 className="mt-2 h400 text-gray-900">{title}</h1>
    </div>

    {image && (
      <div className="rounded-lg h-0 overflow-hidden aspect-w-8 aspect-h-10 md:aspect-w-12 md:aspect-h-7">
        <div className="translate-z-0 transform">
          <GatsbyImage
            className="min-h-full w-full"
            image={image?.localFile?.childImageSharp.gatsbyImageData}
            objectFit="cover"
            objectPosition="center"
            alt={title}
          />
        </div>
      </div>
    )}
    
    {tableOfContents && (
      <TableOfContents content={content?.data?.childMdx?.tableOfContents?.items} />
    )}

    <div className="pb-8 text-gray-500">
      {content?.data?.childMdx && (
        <MDXComponent>{content.data.childMdx.body}</MDXComponent>
      )}
    </div>
  </div>
);

export default BlogEntryComponent;
