import React from 'react';
import { MDXProps } from '../../types/shared';
import { Heading, HeadingProps } from '../heading/heading';
import { MDXComponent } from '../shared/mdx-component';
import convertToSlug from '../../utils/convert-to-slug';

export interface FAQProps {
  id?: string;
  className?: string;
  heading: HeadingProps;
  headingLink?: {
    text: string;
    url: string;
  };
  questions: {
    question: string;
    answer: MDXProps;
  }[];
}

const Description = ({ text, link }) => (
  <>
    {text}{' '}
    {link && (
      <a href={link.url} className="text-green hover:text-green-secondary">
        {link.text}
      </a>
    )}
  </>
);

export const FAQ: React.FC<FAQProps> = ({
  id,
  heading,
  headingLink,
  questions,
  className,
}) => (
  <section id={id} className={className}>
    <div className="max-w-8xl mx-auto py-8 px-4 sm:px-6 lg:py-32 lg:px-8">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        <Heading
          tag="h2"
          title={heading?.title}
          description={
            <Description text={heading?.description} link={headingLink} />
          }
          id={convertToSlug(heading?.title as string)}
          position="left"
        />
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-12">
            {questions &&
              questions.map((faq) => (
                <div key={faq.question}>
                  <dt className="h500 text-gray-900 uppercase">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 body-xs text-gray-500">
                    {faq.answer?.data?.childMdx && (
                      <MDXComponent type="no-style">
                        {faq.answer.data.childMdx.body}
                      </MDXComponent>
                    )}
                  </dd>
                </div>
              ))}
          </dl>
        </div>
      </div>
    </div>
  </section>
);

export default FAQ;
