import ArrowNarrowRightIcon from '@heroicons/react/solid/esm/ArrowNarrowRightIcon';
import React from 'react';
import { LightMDXProps } from '../../types/shared';
import { AnchorLink } from '../shared/anchor-link';
import { MDXComponent } from '../shared/mdx-component';

export interface HeroCardsProps {
  heading: {
    title: string;
  };
  cards: {
    title: string;
    subtitle: LightMDXProps;
    link: {
      text: string;
      url: string;
    };
  }[];
}

const Card = (card) => (
  <>
    <div className="text-white text-base leading-6 lg:text-3xl lg:leading-8 w-full font-medium md:pb-6">
      {card.title}
    </div>
    <div className="text-gray-900 text-sm lg:text-base w-full flex-auto">
      {card.subtitle && (
        <MDXComponent>{card.subtitle?.childMdx?.body}</MDXComponent>
      )}
    </div>

    {card.link && (
      <ArrowNarrowRightIcon
        className="label-base pb-2 h-8 w-8 text-white px-0"
        aria-hidden="true"
      />
    )}
  </>
);

export const HeroCards: React.FC<HeroCardsProps> = ({ heading, cards }) => {
  return (
    <div className="z-2 w-full max-w-full m-auto pt-2">
      <h2 className="w-[50%] lg:w-full text-base lg:text-3xl font-bold block tracking-wide uppercase text-green-secondary pb-6 px-6">
        {heading.title}
      </h2>
      <div className="flex-wrap md:flex-nowrap flex gap-3 md:gap-6 overflow-x-auto px-6">
        {cards.map((card, index) => (
          <div
            key={index}
            className="rounded-5xl p-4 lg:p-5 bg-hero-gradient-mobile lg:bg-hero-gradient w-full lg:h-[260px] md:min-w-[180px] md:w-[180px] lg:min-w-[270px] lg:w-[270px] flex flex-col"
          >
            {card.link ? (
              <AnchorLink className="w-full h-full" to={card.link?.url}>
                <Card {...card} />
              </AnchorLink>
            ) : (
              <Card {...card} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
