import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { ImageProps, MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';

export interface TestimonialComponentProps {
  text: MDXProps;
  image: ImageProps;
}

export const TestimonialComponent: React.FC<TestimonialComponentProps> = ({
  text,
  image,
}) => (
  <div className="relative flex items-end h-full w-full rounded-3xl shadow-xl overflow-hidden min-h-[50rem]">
    <div className="absolute inset-0 h-full w-full">
      {image && (
        <GatsbyImage
          className="h-full w-full"
          image={image?.localFile?.childImageSharp?.gatsbyImageData}
          alt="photo"
          objectPosition="top"
          objectFit="cover"
        />
      )}
    </div>
    <div className="absolute inset-0 bg-gradient-to-t from-green via-green opacity-75"></div>
    <div className="relative testimonial-s flex items-end pb-12 px-8 ">
      <div className="mt-8 text-base text-white">
        {text?.data?.childMdx && (
          <MDXComponent type="no-style">{text.data.childMdx.body}</MDXComponent>
        )}
      </div>
    </div>
  </div>
);

export default TestimonialComponent;
