import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { CookieConsentProps } from 'react-cookie-consent/dist/CookieConsent.props';
import { useTranslationsContextData } from '../../hooks/use-context-data';
import { createPortal } from 'react-dom';
import { useIsServerClient } from '../../hooks/use-is-server-client';

export const CookieConsentComponent: React.FC<Partial<CookieConsentProps>> = ({
  ...props
}) => {
  const { isClient } = useIsServerClient();
  const translations = useTranslationsContextData();

  if (!isClient) return null;

  return createPortal(
    <CookieConsent
      {...props}
      disableStyles={true}
      flipButtons
      declineButtonClasses="text-sm uppercase text-black  font-normal hover:text-white"
      contentClasses="text-white text-sm font-normal flex items-center px-0 md:px-3 md:max-w-xl lg-menu:max-w-4xl"
      buttonWrapperClasses="pt-4 md:pt-0"
      buttonClasses="bg-white rounded-full text-sm uppercase font-normal flex py-2 px-5 hover:text-white hover:bg-black border border-white"
      containerClasses="bg-black opacity-90 mb-5 mx-5 rounded-4xl p-5 lg:p-3 flex flex-wrap justify-between items-center fixed bottom-0 z-50 right-0 lg-menu:max-w-5xl"
    >
      {translations['cookies.text']}
    </CookieConsent>,
    document.body
  );
};
