import cx from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { deepFind } from '../../utils/deep-find';
import { Field } from '../field/field';

export interface RadioGroupOption
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'name, ref, onChange, onBlur'
  > {
  label?: string | JSX.Element;
  className?: string;
  extraContent?: string | JSX.Element;
}

export interface RadioGroupProps {
  name: string;
  options: RadioGroupOption[];
  label?: string;
  disabled?: boolean;
  className?: string;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  options,
  label,
  disabled,
  className,
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('RadioGroup must be inside Form Component');
  }
  const {
    control,
    formState: { errors },
  } = ctx;

  return (
    <Field
      name={name}
      label={label}
      errors={errors}
      className={className}
      fieldset
    >
      {options.map(
        ({
          extraContent,
          label: optionLabel,
          disabled: optionDisabled,
          className: optionClassName,
          ...option
        }) => (
          <Controller
            key={option.id}
            control={control}
            name={name}
            render={({ field }) => (
              <div className={cx('block ml-4 my-2', optionClassName)}>
                <div className={'flex items-center'}>
                  <input
                    type="radio"
                    className={cx(
                      'focus:ring-green h-4 w-4 text-green border-gray-300',
                      {
                        'border border-red-300': Boolean(
                          deepFind(errors, name)
                        ),
                      }
                    )}
                    name={name}
                    {...field}
                    {...option}
                    disabled={disabled || optionDisabled}
                  />
                  <label
                    htmlFor={option.id}
                    className="ml-3 block form-label text-gray-600"
                  >
                    {optionLabel}
                  </label>
                </div>
                <div className="ml-7">{extraContent}</div>
              </div>
            )}
          />
        )
      )}
    </Field>
  );
};
