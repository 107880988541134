import cx from 'classnames';
import React from 'react';
import {
  useModalContextData,
  useTranslationsContextData,
} from '../../../hooks/use-context-data';
import { ModalInfoType } from '../../../types/modal';
import { Button } from '../../button/button';
import { MDXComponent } from '../../shared/mdx-component';
import { ModalContext } from './../modal';
import { ModalContent } from './../modal-content';

export interface ModalInfoProps {
  type: ModalInfoType;
  icon?: string;
  externalCloseModal?: () => void;
  textPosition?: 'left' | 'center';
}

export const ModalInfo: React.FC<ModalInfoProps> = ({
  externalCloseModal,
  type,
  icon = 'info',
  textPosition = 'left',
}) => {
  const { closeModal } = React.useContext(ModalContext);
  const modalData = useModalContextData('info', type);
  const translations = useTranslationsContextData();

  return (
    <ModalContent heading={modalData?.heading} icon={icon || 'info'}>
      {modalData?.content?.childMdx && (
        <div
          className={cx('text-gray-500', {
            'text-left': textPosition === 'left',
            'text-center': textPosition === 'center',
          })}
        >
          <MDXComponent>{modalData.content.childMdx.body}</MDXComponent>
        </div>
      )}

      <Button full type="secondary" onClick={externalCloseModal || closeModal}>
        {translations['button.close']}
      </Button>
    </ModalContent>
  );
};
