import React from 'react';
import { ModalFormType, ModalTypeProps } from '../../types/modal';
import { MDXProps } from '../../types/shared';
import { Button } from '../button/button';
import { Heading, HeadingProps } from '../heading/heading';
import Modal from '../modal/modal';
import { ModalForm } from '../modal/modal-types/form/modal-form';
import { AnchorLink } from '../shared/anchor-link';
import { MDXComponent } from '../shared/mdx-component';

interface Button {
  text: string;
  url?: string;
  modalType?: ModalTypeProps;
}

export interface CTASectionProps {
  className?: string;
  heading: {
    title: string;
    description: MDXProps;
    highlight?: string;
  };
  headingProps: Omit<HeadingProps, 'title' | 'description' | 'highlight'>;
  button?: Button;
  tabs?: Button[];
}

export const CTASection: React.FC<CTASectionProps> = ({
  button,
  tabs,
  className,
  heading,
  headingProps,
}) => {
  return (
    <section className={className}>
      <div className="bg-white px-3 py-8 lg:py-16">
        {heading && (
          <Heading
            className="max-w-8xl mx-auto"
            tag="h2"
            {...headingProps}
            {...heading}
            description={
              heading.description?.data?.childMdx?.rawBody ||
              (heading.description?.data?.childMdx && (
                <MDXComponent>
                  {heading.description?.data?.childMdx?.body}
                </MDXComponent>
              ))
            }
          />
        )}

        {button && (
          <div className="flex justify-center">
            {button.modalType ? (
              <Modal
                buttonText={button.text}
                buttonProps={{
                  type: 'outline',
                  size: 'lg',
                  className: 'mt-11',
                }}
              >
                <ModalForm
                  type={button.modalType?.contentType as ModalFormType}
                />
              </Modal>
            ) : (
              <Button
                href={button.url}
                type="outline"
                size="lg"
                className="mt-11"
              >
                {button.text}
              </Button>
            )}
          </div>
        )}
        {tabs && (
          <div className="flex flex-col lg:flex-row justify-center items-center gap-x-8 gap-y-4 mt-14 flex-wrap">
            {tabs.map((tab, index) => (
              <AnchorLink
                key={`header-tab-${index}`}
                to={tab.url}
                className="label-s uppercase px-3 py-1.5 rounded-md hover:bg-green hover:bg-opacity-30 text-gray-500 hover:text-gray-700"
              >
                {tab.text}
              </AnchorLink>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
