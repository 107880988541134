import { graphql } from 'gatsby';
import React from 'react';
import { Heading } from '../components/heading/heading';
import Layout from '../components/layout/layout';
import RecruitmentComponent from '../components/recruitment/recruitment-component';
import { getUrlLocalizations } from '../utils/parse-localization-url';

export const Recruitment = ({ data }) => {
  if (!data.recruitment) return null;

  const { heading, items, seo } = data.recruitment;

  return (
    <Layout
      {...data}
      seo={seo}
      localizations={getUrlLocalizations(
        'recruitment',
        data.urls.localizations,
        data.urls
      )}
    >
      <section>
        {heading && (
          <Heading
            className="max-w-8xl mx-auto pt-24 pb-16"
            tag="h1"
            {...heading}
          />
        )}
        <RecruitmentComponent id="recruitment-component" items={items} />
      </section>
    </Layout>
  );
};

export default Recruitment;

export const query = graphql`
  query RecruitmentPage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    recruitment: strapiRecruitmentPage(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      heading {
        ...HeadingAllFragment
      }
      items {
        title
        text {
          data {
            childMdx {
              body
            }
          }
        }
        image {
          ...ImageFragment
        }
        button {
          ...ButtonFragment
        }
      }
    }
  }
`;
