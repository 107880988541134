import ArrowNarrowLeftIcon from '@heroicons/react/solid/esm/ArrowNarrowLeftIcon';
import ArrowNarrowRightIcon from '@heroicons/react/solid/esm/ArrowNarrowRightIcon';
import cx from 'classnames';
import React, { useRef } from 'react';
import { useScrolledList } from '../../hooks/use-scrolled-list';
import { ButtonDataProps, MDXProps } from '../../types/shared';
import { Heading, HeadingProps } from '../heading/heading';
import { AnchorLink } from '../shared/anchor-link';
import { MDXComponent } from '../shared/mdx-component';

export interface ScrolledListProps {
  id?: string;
  heading: HeadingProps;
  list: {
    name: string;
    list: ScrolledListElementProps[];
  }[];
}

export interface ScrolledListElementProps {
  title: string;
  text: MDXProps;
  link?: ButtonDataProps;
}

const ScrolledListElement: React.FC<ScrolledListElementProps> = ({
  title,
  text,
  link,
}) => (
  <li className="pb-16 min-w-[16rem] w-[16rem] lg:w-[23rem] lg:min-w-[23rem] bg-white shadow-lg rounded-5xl px-11 py-10">
    <h3 className="text-4xl font-semibold lg:h400-semibold text-gray-900">
      {title}
    </h3>
    <div className="mt-4 text-gray-500">
      <MDXComponent type="xsmall">{text?.data?.childMdx?.body}</MDXComponent>
      <div className="inline-block">
        <AnchorLink
          to={link.url}
          className="button-s lg:button-m text-green hover:text-green-secondary"
        >
          {link.text}
        </AnchorLink>
        <ArrowNarrowRightIcon className="h-5 text-green ml-2 inline-block" />
      </div>
    </div>
  </li>
);

export const ScrolledList: React.FC<ScrolledListProps> = ({
  id,
  heading,
  list,
}) => {
  const containerRef = useRef(null);

  const { prev, next, hideNext, hidePrev } = useScrolledList(containerRef);

  return (
    <section id={id} className="relative">
      <div className="lg:hidden absolute w-full h-full bg-gray-50 -z-1" />
      <div className="absolute w-full h-3/4 lg:h-full bg-gray-200 -z-1" />
      <div className="max-w-8xl mx-auto py-16">
        <div className="flex justify-between items-center px-6 lg:px-8 pb-16 gap-6 lg:gap-8">
          <Heading position="left" tag="h2" {...heading} />
          <div className="hidden lg:flex gap-6">
            <ArrowNarrowLeftIcon
              className={cx('w-6 h-6', {
                'cursor-pointer text-green-secondary hover:text-green':
                  !hidePrev,
                'text-gray-400': hidePrev,
              })}
              onClick={hidePrev ? undefined : prev}
            />

            <ArrowNarrowRightIcon
              className={cx('w-6 h-6', {
                'cursor-pointer text-green-secondary hover:text-green':
                  !hideNext,
                'text-gray-400': hideNext,
              })}
              onClick={hideNext ? undefined : next}
            />
          </div>
        </div>
        <ul
          ref={containerRef}
          className="flex gap-4 px-6 lg:px-8 overflow-x-auto pb-8"
        >
          {list
            .flatMap((l) => l.list)
            .map((item, j) => (
              <ScrolledListElement key={item.title + j} {...item} />
            ))}
        </ul>
      </div>
    </section>
  );
};
