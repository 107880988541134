import { Popover } from '@headlessui/react';
import MenuIcon from '@heroicons/react/outline/esm/MenuIcon';
import { useIntl } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import { ReactComponent as LogoEn } from '../../assets/svg/logo-therapy-center-en.svg';
import { ReactComponent as LogoPl } from '../../assets/svg/logo-therapy-center-pl.svg';
import { MenuProps } from '../../types/menu';
import { ModalFormType } from '../../types/modal';
import { ModalContextProps, ModalDialog } from '../modal/modal';
import { ModalForm } from '../modal/modal-types/form/modal-form';
import { AnchorLink } from '../shared/anchor-link';
import { MenuDesktop } from './desktop/menu-desktop';
import { MenuMobile } from './menu-mobile';
import cx from 'classnames';
export const MenuModalContext: React.Context<
  ModalContextProps & {
    setFormType?: (type: ModalFormType) => void;
  }
> = React.createContext({});

export const Menu: React.FC<{ menu: MenuProps }> = ({ menu }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const [isBackgroundVisible, setIsBackgroundVisible] = React.useState(false);
  const [formType, setFormType] = React.useState<ModalFormType>('therapy');
  const { locale } = useIntl();

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const bgIsVisible = () => {
    window.scrollY > 0
      ? setIsBackgroundVisible(true)
      : setIsBackgroundVisible(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', bgIsVisible);
    return () => {
      window.removeEventListener('scroll', bgIsVisible);
    };
  }, [bgIsVisible]);

  return (
    <MenuModalContext.Provider value={{ openModal, setFormType }}>
      <header
        onScroll={bgIsVisible}
        className={cx(
          'sticky top-0 z-3 w-full transition-all ease-in-out duration-200',
          isBackgroundVisible ? 'bg-white' : 'bg-transparent'
        )}
      >
        <Popover className="relative">
          <div className="flex justify-between items-center max-w-8xl mx-auto px-3 py-6 xl:justify-start xl:gap-10 xl:px-8">
            <div className="flex justify-start xl:w-0 xl:flex-1">
              <AnchorLink to="/">
                <span className="sr-only">Magwise</span>
                {locale === 'en' ? (
                  <LogoEn key="logo" className="h-12 w-auto" />
                ) : (
                  <LogoPl key="logo" className="h-12 w-auto" />
                )}
              </AnchorLink>
            </div>
            <div className="lg:hidden">
              <button
                className="rounded-md p-2 inline-flex items-center justify-center border-2 border-gray-800 text-gray-800 hover:text-gray-800 hover:bg-gray-100"
                onClick={() => setIsMobileMenuOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <MenuDesktop {...menu} />
          </div>
          <MenuMobile
            {...menu}
            isOpen={isMobileMenuOpen}
            closeMenu={() => setIsMobileMenuOpen(false)}
          />
        </Popover>
        <ModalDialog isOpen={isOpen}>
          <ModalForm externalCloseModal={closeModal} type={formType} />
        </ModalDialog>
      </header>
    </MenuModalContext.Provider>
  );
};

export default Menu;
