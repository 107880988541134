import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useFormatDate } from '../../hooks/use-format-date';
import { BlogEntryProps } from '../../types/blog';
import { AnchorLink } from '../shared/anchor-link';

export const BlogIndexElement: React.FC<BlogEntryProps> = ({
  category,
  image,
  title,
  slug,
  pathname,
  subtitle,
  author,
  date,
}) => {
  const format = useFormatDate();

  return (
    <div className="flex flex-col rounded-2xl shadow-lg overflow-hidden">
      {image && (
        <div className="flex-shrink-0">
          <AnchorLink to={`/${pathname}/${slug}`}>
            <GatsbyImage
              className="h-48 w-full"
              image={image?.localFile?.childImageSharp.gatsbyImageData}
              objectFit="cover"
              alt={title}
            />
          </AnchorLink>
        </div>
      )}
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="label-xs font-semibold uppercase text-green">
            <AnchorLink
              to={`/${category.pathname}/${category?.url}`}
              className="hover:underline"
            >
              {category?.name}
            </AnchorLink>
          </p>
          <AnchorLink to={`/${pathname}/${slug}`} className="block mt-2">
            <p className="label-s text-gray-800">{title}</p>
            <p className="mt-3 body-s-auto text-gray-500 blog-ellipsis ">
              {subtitle}
            </p>
          </AnchorLink>
        </div>
        <div className="mt-6 flex items-center">
          {author && <div className="sr-only">{author}</div>}
          <div>
            <p className="label-xs font-medium text-gray-800">{author}</p>
            <div className="flex space-x-1 label-xs text-gray-500">
              {date && <time dateTime={date}>{format(date)}</time>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
