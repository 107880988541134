import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout';
import { TeamMember } from '../../components/team-memeber/team-member';
import { getPathnameSlugLocalizations } from '../../utils/parse-localization-url';

export const TeamMemberPage = ({ data }) => {
  if (!data.member) return null;

  return (
    <Layout
      {...data}
      seo={data.member?.seo}
      localizations={getPathnameSlugLocalizations(data.member)}
    >
      <TeamMember {...data.member} />
    </Layout>
  );
};

export const query = graphql`
  query TeamMemberPage($language: String, $slug: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    member: strapiTeamMember(locale: { eq: $language }, slug: { eq: $slug }) {
      locale
      slug
      pathname
      localizations {
        data {
          attributes {
            locale
            slug
            pathname
          }
        }
      }
      seo {
        ...SeoFragment
      }
      name
      role
      photo {
        ...ImageFragment
      }
      photoMobile {
        ...ImageFragment
      }
      appointment {
        ...ButtonFragment
      }
      description {
        data {
          childMdx {
            body
          }
        }
      }
      categories {
        ...CategoryFragment
      }
    }
  }
`;
export default TeamMemberPage;
