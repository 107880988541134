import { graphql } from 'gatsby';
import React from 'react';
import { AboutUs } from '../components/about-us/about-us';
import { CTA } from '../components/cta/cta';
import Layout from '../components/layout/layout';
import { Team } from '../components/team/team';
import { Gallery } from '../components/gallery/gallery';
import {
  UrlLocalization,
  getUrlLocalizations,
} from '../utils/parse-localization-url';

export const AboutUsPage = ({ data }) => {
  if (!data.aboutUs) return null;
  const { title, subtitle, description, teamMembers, cta, seo, gallery } =
    data.aboutUs;

  return (
    <Layout<UrlLocalization>
      {...data}
      seo={seo}
      localizations={getUrlLocalizations(
        'aboutUs',
        data.urls.localizations,
        data.urls
      )}
    >
      {title && (
        <AboutUs title={title} subtitle={subtitle} description={description} />
      )}
      {teamMembers && <Team {...teamMembers} />}
      {cta && <CTA className="bg-gray-100" {...cta} />}
      {gallery && <Gallery {...gallery} />}
    </Layout>
  );
};

export default AboutUsPage;

export const query = graphql`
  query AboutUsPage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    aboutUs: strapiAboutUsPage(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      title
      subtitle {
        data {
          childMdx {
            body
          }
        }
      }
      description {
        data {
          childMdx {
            body
          }
        }
      }
      teamMembers {
        ...TeamMembersFragment
      }
      cta {
        ...CTAFragment
      }
      gallery {
        id: anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        photos {
          ...GridBlockFragment
        }
      }
    }
  }
`;
