import { AnchorLink as AnchorLinkComponent } from 'gatsby-plugin-anchor-links';
import React from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-react-intl';
import { GatsbyLinkProps } from 'gatsby';

interface LinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  language?: string;
}

export const AnchorLink: React.FC<LinkProps> = ({
  to,
  language,
  children,
  onClick,
  ...rest
}) => {
  return to?.[0] === '/' ? (
    <IntlContextConsumer>
      {(intl) => {
        const languageLink = language || intl.language;
        const link =
          intl.routed || language ? `/${languageLink}${to}` : `${to}`;
        return (
          <AnchorLinkComponent
            {...rest}
            to={link.toLowerCase()}
            onAnchorLinkClick={() => onClick?.(null)}
          >
            {children}
          </AnchorLinkComponent>
        );
      }}
    </IntlContextConsumer>
  ) : (
    <a {...rest} target="_blank" rel="noreferrer" href={to}>
      {children}
    </a>
  );
};
