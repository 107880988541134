import { graphql } from 'gatsby';
import React from 'react';
import { Contact as ContactComponent } from '../components/contact/contact';
import { DrivingDirections } from '../components/driving-directions/driving-directions';
import { Heading } from '../components/heading/heading';
import Layout from '../components/layout/layout';
import { ImageSection } from '../components/image-section/image-section';
import Cards from '../components/cards/cards';
import { getUrlLocalizations } from '../utils/parse-localization-url';

export const Contact = ({ data }) => {
  if (!data.contact) return null;

  const {
    heading,
    transferData,
    clinics,
    parkingMap,
    cooperatingCenters,
    seo,
  } = data.contact;

  return (
    <Layout
      {...data}
      seo={seo}
      localizations={getUrlLocalizations(
        'contact',
        data.urls.localizations,
        data.urls
      )}
    >
      {heading && (
        <section className="px-6 xl:px-0">
          <Heading
            className="max-w-8xl mx-auto pt-16 md:pt-24 pb-16"
            tag="h1"
            {...heading}
          />
          {data.contactData && (
            <ContactComponent
              items={[
                ...data.contactData.items,
                ...(transferData ? [transferData] : []),
              ]}
              socialLinks={data.socialLinks}
            />
          )}
        </section>
      )}
      {clinics && (
        <section id={clinics.anchorId} className="pt-24 pb-20 lg:pb-40">
          <Heading
            className="max-w-8xl mx-auto px-6 xl:px-0 pb-10 lg:pb-24"
            tag="h2"
            {...clinics.heading}
          />
          <div className="max-w-8xl mx-auto grid gap-9">
            {clinics.cards.map((item, i) => (
              <DrivingDirections key={i} {...item} />
            ))}
          </div>
        </section>
      )}
      {parkingMap && <ImageSection unwrap {...parkingMap} />}
      {cooperatingCenters && <Cards type="location" {...cooperatingCenters} />}
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ContactPage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    contact: strapiContactPage(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      heading {
        ...HeadingAllFragment
      }
      transferData {
        label
        text {
          data {
            childMdx {
              body
            }
          }
        }
      }
      clinics {
        anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        cards {
          cardId
          ...CardFragment
        }
      }
      parkingMap {
        id: anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        image {
          ...ImageFragment
        }
      }
      cooperatingCenters {
        id: anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        cards {
          ...CardFragment
          image {
            ...ImageFragment
          }
        }
      }
    }
  }
`;
