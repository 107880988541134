import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { ImageProps, MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';
import { useFormatDate } from '../../hooks/use-format-date';
import UserIcon from '@heroicons/react/outline/UserIcon';

export interface TestimonialProps {
  image?: ImageProps;
  description: string;
  name: string;
  url: string;
  text?: MDXProps;
  className?: string;
  date?: string;
}
export const Testimonial: React.FC<TestimonialProps> = ({
  image,
  description,
  name,
  text,
  className,
  url,
  date,
}) => {
  const format = useFormatDate(true);

  return (
    <div
      className={cx(
        className,
        'bg-white rounded-5xl shadow-xl py-8 px-8 flex flex-col lg:flex-row gap-x-3'
      )}
    >
      <div className="rounded-full">
        {image ? (
          <GatsbyImage
            className="w-16 h-16 rounded-full"
            image={image?.localFile?.childImageSharp?.gatsbyImageData}
            alt={name}
            objectPosition="center"
            objectFit="cover"
          />
        ) : (
          <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center">
            <UserIcon className="w-10 h-10 text-gray-300" />
          </div>
        )}
      </div>
      <div className="relative lg:px-2.5">
        <div className="text-base font-semibold text-green my-2 lg:mt-0">
          {url ? (
            <a className="hover:text-green-secondary" href={url}>
              {name}
            </a>
          ) : (
            <span>{name}</span>
          )}
        </div>
        <blockquote className="text-gray-900">
          {text.data?.childMdx && (
            <MDXComponent type="testimonials">
              {text.data.childMdx.body}
            </MDXComponent>
          )}
          <footer className="mt-6 text-gray-600 body-xxs">
            <p className="flex gap-x-3 items-center flex-wrap">
              {date && (
                <>
                  <span>{format(date)}</span>
                  {description && (
                    <span className="hidden lg:block w-2 h-2 bg-green rounded-full" />
                  )}
                </>
              )}
              <span>{description}</span>
            </p>
          </footer>
        </blockquote>
      </div>
    </div>
  );
};
