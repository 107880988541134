import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Heading, HeadingProps } from '../heading/heading';
import convertToSlug from '../../utils/convert-to-slug';

interface StatProps {
  value: number;
  text: string;
  valuePrefix?: string;
  isPercentage?: boolean;
}

export interface StatsProps {
  id?: string;
  heading: HeadingProps;
  stats: StatProps[];
}

const Stat: React.FC<StatProps> = ({
  value,
  text,
  isPercentage,
  valuePrefix,
}) => {
  const [active, setActive] = React.useState(false);

  return (
    <div className="max-w-md w-full flex flex-col items-center justify-start px-6">
      <div className="h100 leading-none text-yellow flex">
        {valuePrefix && <div>{valuePrefix}</div>}
        <div className="min-w-[10rem] text-right">
          <CountUp start={active ? 0 : null} end={value}>
            {({ countUpRef }) => (
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible) {
                    setActive(true);
                  }
                }}
                delayedCall
              >
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
        </div>

        {isPercentage && <div>%</div>}
      </div>
      <p className="pt-2 body-l-medium text-grat-600 text-center">{text}</p>
    </div>
  );
};

export const Stats: React.FC<StatsProps> = ({ heading, stats, id }) => (
  <section id={id} className="bg-gray-50">
    <div className="mx-auto max-w-8xl py-16 lg:py-32 xl:px-0">
      <Heading
        className="px-8"
        tag="h2"
        {...heading}
        id={convertToSlug(heading?.title as string)}
      />

      <div className="mx-auto pt-20 px-12 flex flex-col md:flex-row gap-y-6 justify-center items-center md:items-start">
        {stats.map((item) => (
          <Stat key={item.text} {...item} />
        ))}
      </div>
    </div>
  </section>
);
