import React from 'react';
import { ModalContent } from './../modal-content';
import { useIntl } from 'gatsby-plugin-react-intl';

export interface ModalVideoProps {
  youtubeUrlHash: string;
  title: string;
  onClose: () => void;
}

export const ModalVideo: React.FC<ModalVideoProps> = ({
  youtubeUrlHash,
  title,
  onClose,
}) => {
  const { locale } = useIntl();
  return (
    <ModalContent size="lg" onClose={onClose}>
      <div className="w-modal-body h-modal-body overflow-auto">
        <div className="max-w-4xl md:max-w-5xl mx-auto">
          <div className="aspect-w-16 aspect-h-9">
            <iframe
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${youtubeUrlHash}?enablejsapi=1&hl=${locale}&modestbranding=1&color=white&iv_load_policy=3`}
              title={title}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </ModalContent>
  );
};
