import XCircleIcon from '@heroicons/react/solid/esm/XCircleIcon';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useRef,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslationsContextData } from '../../hooks/use-context-data';
import { Button } from '../button/button';
import { Field } from '../field/field';

export interface UploadFilesProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (files: FileList) => void;
  label?: string;
  constainerClassName?: string;
}
export const UploadFiles: React.FC<UploadFilesProps> = ({
  onChange,
  label,
  constainerClassName,
  ...props
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('Upload file must be inside Form Component');
  }
  const {
    control,
    formState: { errors },
  } = ctx;

  const translations = useTranslationsContextData();
  const inputElem = useRef<HTMLInputElement>();
  const uploadSingleFile = useCallback(
    (
      changeFormFunc: (...event: any[]) => void,
      event: ChangeEvent<HTMLInputElement>,
      value: any
    ) => {
      const list = Array.from(event.target.files);
      changeFormFunc([...value, ...list]);
    },
    []
  );

  const deleteFile = useCallback(
    (changeFormFunc: (...event: any[]) => void, fileName, value: any) => {
      const list = value.filter((file) => file.name !== fileName);
      changeFormFunc(list);
    },
    []
  );

  return (
    <Field
      name={props.name}
      label={label}
      errors={errors}
      className={constainerClassName}
    >
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <>
            <div className="flex flex-row gap-x-6 items-start mt-6 p-6">
              <span className="label-xs text-gray-500 flex-auto">
                {translations['form.attachment-description']}
              </span>
              <Button
                className="flex-none label-xs font-normal"
                size="sm"
                onClick={() => inputElem.current.click()}
                htmlType="button"
              >
                {translations['form.select-file']}
              </Button>
            </div>
            {value.map((file, i) => (
              <div key={i} className="flex gap-x-4 items-center">
                <p className="label-xs text-gray-500">{file.name}</p>
                <XCircleIcon
                  className="h-5 w-5 text-red-600 cursor-pointer"
                  onClick={() => deleteFile(onChange, file.name, value)}
                  aria-hidden="true"
                />
              </div>
            ))}
            <input
              {...props}
              ref={inputElem}
              onChange={(e) => uploadSingleFile(onChange, e, value)}
              className="hidden"
              accept="image/*, .pdf, .doc, .docx"
              type="file"
              multiple
            />
          </>
        )}
      />
    </Field>
  );
};
