import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import { Heading, HeadingProps } from '../heading/heading';
import {
  TeamMemberCategory,
  TeamMemberProps,
} from '../team-memeber/team-member';
import cx from 'classnames';
import { TeamGrid } from './team-grid';
import { useIsServerClient } from '../../hooks/use-is-server-client';
import convertToSlug from '../../utils/convert-to-slug';

export interface TeamMemberBlockProps {
  teamMember: TeamMemberProps;
  size: 'small' | 'large';
}

export interface TeamProps {
  id: string;
  heading: HeadingProps;
  teamMembers: TeamMemberBlockProps[];
  teamCategories?: TeamMemberCategory[];
  className?: string;
}

export const Team: React.FC<TeamProps> = ({
  id,
  heading,
  teamMembers: members,
  teamCategories: categories,
  className
}) => {
  const { isClient } = useIsServerClient();
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState<undefined | string>();

  const urlSearchParams = isClient
    ? new URLSearchParams(window.location.search)
    : undefined;

  const categoryQuery = urlSearchParams?.get('category');

  useEffect(() => {
    const category =
      categoryQuery && categories.find((c) => c.value === categoryQuery);

    if (category) {
      setActiveCategory(category.value);
    }
  }, [categoryQuery]);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <section id={id} className={cx(className, 'px-6 py-8 lg:py-16 bg-white')}>
      <div className="max-w-5xl mx-auto" ref={containerRef}>
        <Heading
          className="mb-16 md:mb-24"
          tag="h2"
          {...heading}
          id={convertToSlug(heading?.title as string)}
        />
        {categories?.length > 0 && (
          <div className="flex justify-center mx-auto gap-2 pt-8 pb-10 lg:pt-5 lg:pb-24 w-4/5">
            <div className="group p-0.5 rounded-md flex flex-col justify-center flex-wrap md:flex-row gap-4">
              {categories.map((category) => (
                <button
                  key={category.value}
                  onClick={() => {
                    const newCategory =
                      activeCategory === category.value
                        ? undefined
                        : category.value;
                    setActiveCategory(newCategory);
                    history.pushState(
                      {},
                      '',
                      `${location.pathname}${
                        newCategory ? `?category=${category.value}` : ''
                      }${location.hash}`
                    );
                  }}
                  className={cx(
                    'px-2 py-1 rounded-md button-m',
                    activeCategory === category.value
                      ? 'text-gray-700 bg-green bg-opacity-30'
                      : 'text-gray-500 hover:bg-green hover:bg-opacity-10'
                  )}
                >
                  {category.label}
                </button>
              ))}
            </div>
          </div>
        )}
        <TeamGrid
          containerRef={containerRef}
          members={members.filter(
            ({ teamMember }) =>
              !activeCategory ||
              teamMember?.categories?.some(
                (category) => category.value === activeCategory
              )
          )}
        />
      </div>
    </section>
  );
};
