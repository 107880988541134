import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { Heading } from '../components/heading/heading';
import Layout from '../components/layout/layout';
import { getSlugLocalizations } from '../utils/parse-localization-url';

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-has-content */

export const StaticContentPage = ({ data }) => {
  if (!data.content) return null;

  const { heading, content, seo, localizations } = data.content;

  return (
    <Layout
      {...data}
      seo={seo}
      localizations={getSlugLocalizations(localizations, data.content)}
    >
      <article>
        <div className="max-w-8xl mx-auto py-16 px-6 lg:pt-28 lg:px-8 prose">
          <Heading className="" {...heading} />
          {content?.data.childMdx && (
            <MDXProvider
              components={{
                h1: (props) => (
                  <h1 {...props} className="body-l text-gray-900 pt-14 pb-8" />
                ),
                h2: (props) => (
                  <h2 {...props} className="body-l text-gray-900" />
                ),
                h3: (props) => (
                  <h3 {...props} className="body-l text-gray-900" />
                ),
                p: (props) => (
                  <p {...props} className="text-gray-500 body-xs" />
                ),
                a: (props) => (
                  <a
                    {...props}
                    className="text-green break-words body-xs"
                    target="__blank"
                    rel="noreferrer"
                  />
                ),
                ul: (props) => (
                  <ul
                    {...props}
                    className="text-gray-500 list-static-content body-xs"
                  />
                ),
                li: (props) => <li {...props} />,
                q: (props) => (
                  <blockquote
                    {...props}
                    className="border-l-4 border-gray-200 text-lg leading-8 font-medium italic text-gray-900 px-4 mb-6"
                  />
                ),
                small: (props) => (
                  <small
                    {...props}
                    className="text-sm leading-6 font-normal pb-6"
                  />
                ),
                br: (props) => <br {...props} />,
              }}
            >
              <MDXRenderer>{content?.data.childMdx.body}</MDXRenderer>
            </MDXProvider>
          )}
        </div>
      </article>
    </Layout>
  );
};

export default StaticContentPage;

export const query = graphql`
  query StaticContentPage($language: String, $slug: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    content: strapiStaticContent(
      locale: { eq: $language }
      slug: { eq: $slug }
    ) {
      locale
      slug
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      seo {
        ...SeoFragment
      }
      heading {
        ...HeadingTitleFragment
      }
      content {
        data {
          childMdx {
            body
          }
        }
      }
    }
  }
`;
