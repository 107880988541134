import { graphql } from 'gatsby';
import { CTA } from '../../components/cta/cta';
import { DiseaseArticle } from '../../components/diseases-articles/disease-article';
import FAQ from '../../components/faq/faq';
import Layout from '../../components/layout/layout';
import { Heading } from '../../components/heading/heading';
import { getPathnameSlugLocalizations } from '../../utils/parse-localization-url';
import TableOfContents from '../../components/table-of-contents/table-of-contents';
import { getTableOfContents } from '../../utils/get-table-of-contents';

export const Clinic = ({ data }) => {
  if (!data.clinic) return null;

  const { heading, content, cta, faq, seo, tableOfContents } = data.clinic;
  return (
    <Layout
      {...data}
      seo={seo}
      localizations={getPathnameSlugLocalizations(data.clinic)}
    >
      {heading && (
        <section className="max-w-8xl mx-auto px-6 lg:px-8">
          <Heading {...heading} className="py-16 lg:py-28" />
        </section>
      )}

      {tableOfContents && content && (
        <div className="px-6 xl:px-0 max-w-8xl mx-auto">
          <TableOfContents content={getTableOfContents(content)} />
        </div>
      )}

      {content?.map((item, index) => (
        <DiseaseArticle
          key={`content-${index}`}
          id={item.contentId}
          type="image-right"
          headingProps={{
            tag: index === 0 && !heading ? 'h1' : 'h2',
          }}
          {...item}
        />
      ))}
      {faq && <FAQ className="bg-white" {...faq} />}
      {cta && (
        <CTA
          className="bg-gray-100"
          {...cta}
          button={{
            ...cta.button,
          }}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query ClinicPage($language: String, $slug: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    clinic: strapiClinic(locale: { eq: $language }, slug: { eq: $slug }) {
      locale
      slug
      pathname
      localizations {
        data {
          attributes {
            locale
            slug
            pathname
          }
        }
      }
      heading {
        ...HeadingAllFragment
      }
      seo {
        ...SeoFragment
      }
      tableOfContents
      content {
        heading {
          ...HeadingAllFragment
        }
        ...ContentFragment
      }
      faq {
        ...FAQFragment
      }
      cta {
        ...CTAFragment
      }
    }
  }
`;
export default Clinic;
