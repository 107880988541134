function convertToSlug(input: string) {
  if (!input || typeof input !== 'string') {
    return '';
  }
  // Replace Polish characters with their ASCII equivalents
  const normalized = input
    .toLowerCase()
    .replace(/ą/g, 'a')
    .replace(/ć/g, 'c')
    .replace(/ę/g, 'e')
    .replace(/ł/g, 'l')
    .replace(/ń/g, 'n')
    .replace(/ó/g, 'o')
    .replace(/ś/g, 's')
    .replace(/ź/g, 'z')
    .replace(/ż/g, 'z');

  // Replace spaces with hyphens
  const slug = normalized.replace(/\s+/g, '-');

  // Remove any non-alphanumeric characters except hyphens
  const cleanedSlug = slug.replace(/[^a-z0-9-]/g, '');

  // check if first letter starts with string, otherwise add 'a' letter to beginning
  const isFirstLetterString = /^[a-zA-Z]/.test(cleanedSlug[0]);
  if (!isFirstLetterString) {
    return 'a' + cleanedSlug;
  }

  return cleanedSlug;
}

export default convertToSlug;
