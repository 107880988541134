import React from 'react';
import { CTASection, CTASectionProps } from '../../cta-section/cta-section';
import { Heading, HeadingProps } from '../../heading/heading';
import {
  PricingComponent,
  PricingComponentProps,
} from '../pricing-component/pricing-component';
import cx from 'classnames';
import { useSettingsContextData } from '../../../hooks/use-context-data';

export interface PricingSectionProps {
  heading: HeadingProps;
  pricings: PricingComponentProps[];
  cta?: CTASectionProps;
  id: string;
  isSubSection?: boolean;
}

export const PricingSection: React.FC<PricingSectionProps> = ({
  heading,
  isSubSection,
  pricings,
  cta,
  id,
}) => {
  const { isProbonoOn } = useSettingsContextData();

  return (
    <>
      <section
        className={cx(
          'mx-auto px-3 xl:px-0 bg-gray-100',
          isSubSection ? 'pb-9 lg:pb-24' : 'py-9 lg:py-24'
        )}
        id={id}
      >
        <Heading
          className="max-w-8xl mx-auto"
          titleClassName={isSubSection ? 'h450' : undefined}
          tag={isSubSection ? 'h3' : 'h2'}
          {...heading}
        />
        {pricings && (
          <div>
            {pricings.map(
              (item, i) =>
                (item.type === 'pro-bono' ? isProbonoOn : true) && (
                  <PricingComponent
                    key={`${item.title}-${i}`}
                    className={cx('first:mt-12 mb-9 last:mb-0')}
                    {...item}
                  />
                )
            )}
          </div>
        )}
      </section>
      {cta && <CTASection className="bg-gray-100 pb-9 lg:pb-24" {...cta} />}
    </>
  );
};
