import cx from 'classnames';
import React from 'react';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';

export interface AboutUsProps {
  className?: string;
  title: string;
  subtitle: MDXProps;
  description: MDXProps;
}

export const AboutUs: React.FC<AboutUsProps> = ({
  className,
  title,
  subtitle,
  description,
}) => (
  <section className={cx(className, 'bg-gray-50 py-16 md:py-24 px-10')}>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center max-w-8xl md:mx-auto md:px-8">
      <div className="col-span-2 text-gray-500">
        <h1 className="h400 text-gray-900 mb-5">{title}</h1>
        {subtitle?.data?.childMdx && (
          <MDXComponent>{subtitle.data.childMdx.body}</MDXComponent>
        )}
        {description?.data?.childMdx && (
          <MDXComponent type="small">
            {description.data.childMdx.body}
          </MDXComponent>
        )}
      </div>
      <Logo className="hidden md:block" />
    </div>
  </section>
);
