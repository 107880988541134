import React, { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';
import { deepFind } from '../../utils/deep-find';
import { Field } from '../field/field';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'ref, onChange, onBlur'> {
  label?: string;
  containerClassName?: string;
}

export const getInputClass = (
  className: string,
  disabled: boolean,
  invalid: boolean
) =>
  cx(
    className,
    'shadow-sm border focus:ring-green focus:border-green block w-full steps-s lg:body-xs text-gray-700 placeholder:text-gray-300 border-gray-300 rounded-md px-3 py-2',
    {
      'bg-gray-50': disabled,
      'border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500':
        invalid,
    }
  );

export const Input: React.FC<InputProps> = ({
  label,
  containerClassName,
  className,
  name,
  ...props
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('Input must be inside Form Component');
  }
  const {
    register,
    formState: { errors },
  } = ctx;
  const registerProps = register(name);
  const hasError = Boolean(deepFind(errors, name));

  return (
    <Field
      name={name}
      label={label}
      errors={errors}
      className={containerClassName}
    >
      <input
        className={getInputClass(className, props.disabled, hasError)}
        {...props}
        {...registerProps}
      />
    </Field>
  );
};
