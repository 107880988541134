import React, { useCallback, useEffect } from 'react';

export const useScrolledList = (parent) => {
  const [firstVisibleIndex, setFirstVisibleIndex] = React.useState(null);
  const [lastVisibleIndex, setLastVisibleIndex] = React.useState(null);

  const setActiveChild = useCallback(() => {
    if (!parent) return;

    for (let i = 0; i < parent.current.children.length; i++) {
      const child = parent.current.children[i];
      const childBoundries = child.getBoundingClientRect();
      const isFirstInViewport = childBoundries.left >= 0;

      setFirstVisibleIndex(i);

      if (isFirstInViewport) {
        break;
      }
    }

    for (let i = 0; i < parent.current.children.length; i++) {
      const child = parent.current.children[i];
      const childBoundries = child.getBoundingClientRect();

      const isLastInViewport =
        childBoundries.right <= parent.current.clientWidth;

      if (!isLastInViewport) {
        break;
      }

      setLastVisibleIndex(i);
    }
  }, [parent]);

  const prev = useCallback(() => {
    if (!parent || firstVisibleIndex - 1 < 0) return;

    parent.current.children[firstVisibleIndex - 1].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });

    setActiveChild();
  }, [parent, setActiveChild, firstVisibleIndex]);

  const next = useCallback(() => {
    if (!parent || lastVisibleIndex + 1 >= parent.current.children.length)
      return;

    parent.current.children[lastVisibleIndex + 1].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'end',
    });
    setActiveChild();
  }, [parent, setActiveChild, lastVisibleIndex]);

  useEffect(() => {
    setActiveChild();
  }, [parent]);

  useEffect(() => {
    parent?.current?.addEventListener('scroll', setActiveChild);

    return () => {
      parent?.current?.removeEventListener('scroll', setActiveChild);
    };
  }, [parent, setActiveChild]);

  return {
    prev,
    next,
    hidePrev: firstVisibleIndex === 0,
    hideNext: lastVisibleIndex === parent?.current?.children?.length - 1,
  };
};
