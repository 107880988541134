import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import { XBlock, XMasonry } from 'react-xmasonry';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { useIsServerClient } from '../../hooks/use-is-server-client';
import useWindowDimensions from '../../hooks/use-window-dimensions';
import { TeamMemberBlockProps } from './team';
import { AnchorLink } from '../shared/anchor-link';

export interface TeamGridProps {
  members: TeamMemberBlockProps[];
  containerRef: React.RefObject<HTMLDivElement>;
}

export const TeamGrid: React.FC<TeamGridProps> = ({
  members,
  containerRef,
}) => {
  const { isMedium } = useIsMobile();
  const { isClient } = useIsServerClient();
  const { width: windowWidth } = useWindowDimensions(isClient);

  const width = useMemo(
    () => containerRef?.current?.clientWidth || 0,
    [containerRef.current]
  );

  const blockWidth = useMemo(
    () => (isMedium ? width / 2 - 20 : width / 4 - 20),
    [isMedium, width]
  );

  if (!windowWidth || !width) return null;

  return (
    <XMasonry
      center={false}
      targetBlockWidth={blockWidth}
      maxColumns={isMedium ? 2 : 4}
    >
      {members?.map(({ teamMember, size }, i) => {
        if (!teamMember) {
          return;
        }

        const isLarge = size === 'large';

        return (
          <XBlock key={`${teamMember?.name}_${i}`} width={isLarge ? 2 : 1}>
            <div className="p-2">
              <div
                className={cx(
                  'text-white text-xs md:text-base font-normal tracking-wider w-full rounded-5xl overflow-hidden relative group'
                )}
                style={{
                  height: `${isLarge ? blockWidth * 2.5 : blockWidth * 1.25}px`,
                }}
              >
                <AnchorLink to={`/${teamMember?.pathname}/${teamMember?.slug}`}>
                  <div
                    className="duration-500 flex absolute bottom-0 w-full h-full z-1 p-6
                  bg-team-gradient to-gray-700 flex-col justify-end"
                  >
                    <div
                      className={cx(
                        isLarge ? 'text-lg md:text-3xl' : 'text-base'
                      )}
                    >
                      {teamMember?.name}
                    </div>
                  </div>
                </AnchorLink>
                {teamMember?.photo ? (
                  <GatsbyImage
                    className="h-full w-full group-hover:scale-[1.07] ease-in-out duration-700"
                    image={
                      teamMember?.photo?.localFile?.childImageSharp
                        .gatsbyImageData
                    }
                    objectFit="cover"
                    alt=""
                  />
                ) : (
                  <div className="bg-gray-200 w-full h-full" />
                )}
              </div>
            </div>
          </XBlock>
        );
      })}
    </XMasonry>
  );
};
