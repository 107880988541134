import React, { useMemo } from 'react';
import cx from 'classnames';
import { ModalForm } from '../modal/modal-types/form/modal-form';
import { Button } from '../button/button';
import Modal from '../modal/modal';
import {
  ModalFormType,
  ModalInfoType,
  ModalTypeProps,
} from '../../types/modal';
import { ModalPayment } from '../modal/modal-types/modal-payment';
import { ModalInfo } from '../modal/modal-types/modal-info';
import convertToSlug from '../../utils/convert-to-slug';

export interface CTAProps {
  id?: string;
  heading: { title: string; description?: string };
  button: {
    text: string;
    url?: string;
    modalType?: ModalFormType;
    positonRight?: boolean;
  };
  className?: string;
  modalType?: ModalTypeProps;
}

export const CTA: React.FC<CTAProps> = ({
  id,
  heading,
  button,
  className,
  modalType,
}) => {
  const ModalContent = useMemo(() => {
    if (modalType) {
      switch (modalType?.type) {
        case 'payment':
          return <ModalPayment />;
        case 'form':
          return <ModalForm type={modalType?.contentType as ModalFormType} />;
        case 'info':
        default:
          return <ModalInfo type={modalType?.contentType as ModalInfoType} />;
      }
    }
  }, [modalType]);

  return (
    <section
      id={id}
      className={cx(className, 'px-8 py-8', {
        'lg:py-24': button.positonRight,
        'lg:py-16': !button.positonRight,
      })}
    >
      <div
        className={cx('max-w-8xl mx-auto flex items-center flex-col gap-y-8', {
          'lg:flex-row lg:justify-between': button.positonRight,
        })}
      >
        <div
          className={cx('text-center', {
            'lg:text-left': button.positonRight,
          })}
        >
          <h2 className="h400" id={convertToSlug(heading?.title as string)}>
            {heading?.title}
          </h2>
          {heading?.description && (
            <h3 className="button-m tracking-wide md:h500 md:tracking-normal text-green uppercase md:normal-case pt-2 md:pt-0">
              {heading?.description}
            </h3>
          )}
        </div>
        {modalType ? (
          <Modal
            buttonText={button.text}
            buttonProps={{ type: 'primary', className: 'px-5 py-3.5' }}
          >
            {ModalContent}
          </Modal>
        ) : (
          <Button
            href={button.url}
            type="primary"
            className="px-5 py-3.5"
            tagName="a"
          >
            {button.text}
          </Button>
        )}
      </div>
    </section>
  );
};
