import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { MapContext } from './map-context';

export const Map: React.FC<{
  address: string;
}> = ({ address }) => {
  const { setMap, setCoddedAddress } = React.useContext(MapContext);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  });

  const center = React.useMemo(
    () => ({
      lat: 52.237049,
      lng: 21.017532,
    }),
    []
  );

  const onLoad = React.useCallback((googleMap) => {
    const bounds = new window.google.maps.LatLngBounds();
    googleMap.fitBounds(bounds);
    setMap(googleMap);

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == 'OK') {
        const { location } = results[0].geometry;
        googleMap.setCenter(location);
        setCoddedAddress(location);

        new google.maps.Marker({
          map: googleMap,
          position: location,
        });

        googleMap.setZoom(17);
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      center={center}
      zoom={1}
      onLoad={onLoad}
      onUnmount={onUnmount}
    />
  ) : null;
};
