import { graphql } from 'gatsby';
import React from 'react';
import { Button } from '../components/button/button';
import Layout from '../components/layout/layout';
import { parseTranslations } from '../utils/parse-translations';

const NotFound = ({ data }) => {
  const { items } = data.translations;
  const translations = parseTranslations(items);

  return (
    <Layout {...data} seo={{ metaTitle: translations['seo.not-found'] }}>
      <div className="h-7/10-height flex flex-col gap-10 justify-center items-center">
        <div className="h200">404</div>
        <h1 className="h400">{translations['seo.not-found']}</h1>
        <Button href="/" type="primary">
          {translations['button.go-back']}
        </Button>
      </div>
    </Layout>
  );
};

export default NotFound;

export const query = graphql`
  query NotForundPage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
  }
`;
