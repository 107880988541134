import React from 'react';
import { DataContext } from '../components/layout/layout';
import { ModalTypeProps } from '../types/modal';
import { SettingsProps } from '../types/shared';

export const useModalContextData: (
  type: string,
  contentType?: string
) => ModalTypeProps | Record<string, never> = (type, contentType) => {
  const { modals } = React.useContext(DataContext);

  if (!modals) return {};

  const data = modals.find(
    (modal) =>
      modal.type === type &&
      (contentType ? modal.contentType === contentType : true)
  );

  return data;
};

export const useTranslationsContextData: () => Record<string, string> = () => {
  const { translations } = React.useContext(DataContext);

  return translations || {};
};

export const useSettingsContextData: () => SettingsProps = () => {
  const { settings } = React.useContext(DataContext);

  return settings || {};
};

export const useLocalizationsContextData: () => Record<string, string> = () => {
  const { localizationUrls } = React.useContext(DataContext);

  return localizationUrls || {};
};
