import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  useModalContextData,
  useTranslationsContextData,
} from '../../../hooks/use-context-data';
import { usePayU } from '../../../hooks/use-payU';
import { PaymentProps } from '../../../types/payment';
import { Button } from '../../button/button';
import { Checkbox } from '../../checkbox/checkbox';
import { Input } from '../../input/input';
import { LoadingSpinner } from '../../shared/loading-spinner';
import { ModalContext } from '../modal';
import { ModalContent } from '../modal-content';

export interface ModalPaymentProps {
  paymentProps?: PaymentProps;
  externalCloseModal?: () => void;
}

export const ModalPayment: React.FC<ModalPaymentProps> = ({
  paymentProps,
  externalCloseModal,
}) => {
  const { closeModal } = React.useContext(ModalContext);
  const { createPayment } = usePayU();
  const modalData = useModalContextData('payment');
  const translations = useTranslationsContextData();

  const [isLoading, setIsLoading] = React.useState(false);

  const modalContentProps = useMemo(
    () => ({
      ...modalData,
      headerContent: (
        <div className="pt-9">
          <div className="flex flex-col lg:flex-row items-baseline justify-between gap-x-4 pb-2 text-left">
            <p className="body-s lg:body-m flex-none">
              {translations['form.payment-title-label']}
            </p>
            <p className="menu-links-18 lg:button-m lg:text-right">
              {paymentProps.title}
            </p>
          </div>
          <div className="flex flex-col lg:flex-row items-baseline justify-between gap-x-4 text-left">
            <p className="body-s lg:body-m flex-none">
              {translations['form.payment-amount-label']}
            </p>
            <p className="menu-links-18 lg:button-m lg:text-right">
              {paymentProps.price.value} {paymentProps.price.currency}
            </p>
          </div>
        </div>
      ),
    }),
    []
  );

  const requiredError = translations['form.required-field-error'];

  const form = useForm({
    defaultValues: {
      patient: {
        firstName: '',
        lastName: '',
      },
      differentPayer: false,
      payer: {
        firstName: '',
        lastName: '',
      },
      email: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        patient: yup.object().shape({
          firstName: yup.string().required(requiredError),
          lastName: yup.string().required(requiredError),
        }),
        payer: yup.object().when('differentPayer', {
          is: true,
          then: yup.object().shape({
            firstName: yup.string().required(requiredError),
            lastName: yup.string().required(requiredError),
          }),
        }),
        email: yup
          .string()
          .required(requiredError)
          .email(translations['form.email-format-error']),
      })
    ),
  });

  const onSubmit = (formData) => {
    setIsLoading(true);
    const price = parseFloat(paymentProps.price.value.toString());

    createPayment(
      {
        order: {
          ...formData,
          totalAmount: price * 100,
          currencyCode: paymentProps.price.currency,
          products: [
            {
              name: paymentProps.title,
              unitPrice: price,
              quantity: 1,
            },
          ],
        },
      },
      () => setIsLoading(false)
    );
  };

  return (
    <ModalContent {...modalContentProps}>
      <FormProvider {...form}>
        <form
          className="text-left flex flex-col gap-5"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col lg:flex-row gap-5">
            <Input
              containerClassName="w-full"
              name="patient.firstName"
              label={translations['form.patient-firstName-label']}
              placeholder={translations['form.firstName-placeholder']}
            />
            <Input
              containerClassName="w-full"
              name="patient.lastName"
              label={translations['form.patient-lastName-label']}
              placeholder={translations['form.lastName-placeholder']}
            />
          </div>
          <Checkbox className="mb-4 text-gray-700" name="differentPayer">
            {translations['form.different-payer-label']}
          </Checkbox>
          {form.watch('differentPayer') && (
            <div className="flex flex-col lg:flex-row gap-5">
              <Input
                containerClassName="w-full"
                name="payer.firstName"
                label={translations['form.payer-firstName-label']}
                placeholder={translations['form.firstName-placeholder']}
              />
              <Input
                containerClassName="w-full"
                name="payer.lastName"
                label={translations['form.payer-lastName-label']}
                placeholder={translations['form.lastName-placeholder']}
              />
            </div>
          )}
          <Input
            name="email"
            label={translations['form.patient-email-label']}
            placeholder={translations['form.email-text-placeholder']}
          />
          <div className="grid grid-cols-1 gap-6">
            <Button
              full
              type="outline-light"
              onClick={externalCloseModal || closeModal}
              size="sm"
              htmlType="button"
            >
              {translations['button.cancel']}
            </Button>
            <Button full htmlType="submit" size="sm" disabled={isLoading}>
              {isLoading ? (
                <div className="flex justify-center py-1">
                  <LoadingSpinner />
                </div>
              ) : (
                translations['button.send']
              )}
            </Button>
          </div>
        </form>
      </FormProvider>
    </ModalContent>
  );
};
