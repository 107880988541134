import ChevronRightIcon from '@heroicons/react/outline/ChevronRightIcon';
import cx from 'classnames';
import React from 'react';
import { AnchorLink } from '../shared/anchor-link';

export interface BreadcrumbsProps {
  className?: string;
  steps: { name: string; url?: string }[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  className,
  steps,
}) => {
  return (
    <div
      className={cx(
        className,
        'max-w-8xl mx-auto px-6 lg:px-8 py-4 lg:py-8 lg:pb-0',
        'text-xs font-normal text-slate-400 flex items-center gap-4 overflow-x-hidden'
      )}
    >
      {steps.map((elem, i) => (
        <React.Fragment key={i}>
          {i > 0 && <ChevronRightIcon className="h-3 text-slate-500" />}
          {elem.url ? (
            <AnchorLink className="hover:text-green" to={elem.url}>
              {elem.name}
            </AnchorLink>
          ) : (
            elem.name
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
