import { graphql } from 'gatsby';
import { GridBlocksList } from '../components/diseases-list/diseases-list';
import { Heading } from '../components/heading/heading';
import Layout from '../components/layout/layout';
import { getUrlLocalizations } from '../utils/parse-localization-url';

export const DiseaseIndex = ({ data }) => {
  if (!data.diseasePage) return null;

  const { heading, diseasesList, seo } = data.diseasePage;

  return (
    <Layout
      {...data}
      seo={seo}
      localizations={getUrlLocalizations(
        'diseases',
        data.urls.localizations,
        data.urls
      )}
    >
      {heading && (
        <Heading
          className="max-w-8xl mx-auto pt-24 pb-16"
          tag="h1"
          {...heading}
        />
      )}
      {diseasesList && (
        <section className="max-w-8xl mx-auto pb-16 px-6 lg:pb-24 lg:px-8">
          <GridBlocksList section={diseasesList} />
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query DiseaseIndex($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    diseasePage: strapiDiseasePage(locale: { eq: $language }) {
      seo {
        ...SeoFragment
      }
      heading {
        ...HeadingAllFragment
      }
      diseasesList {
        heading {
          ...HeadingTitleDescriptionFragment
        }
        blocks {
          ...GridBlockFragment
        }
      }
    }
  }
`;

export default DiseaseIndex;
