import React from 'react';
import cx from 'classnames';
import { useFormContext } from 'react-hook-form';
import { CheckboxProps } from './checkbox';
import { ErrorMessage } from '@hookform/error-message';
import CheckIcon from '@heroicons/react/solid/esm/CheckIcon';
import { deepFind } from '../../utils/deep-find';

export interface Props extends CheckboxProps {
  inputRef: React.LegacyRef<HTMLInputElement>;
}

const CheckboxField: React.FC<Props> = ({
  className,
  children,
  inputRef,
  name,
  ...props
}) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('CheckboxField must be inside Form Component');
  }

  const {
    formState: { errors },
  } = ctx;
  const hasError = Boolean(deepFind(errors, name));

  return (
    <div className={className}>
      <div
        className={cx('flex items-center', {
          'pointer-events-none': props.disabled,
        })}
      >
        <label
          className={cx('relative flex justify-center items-center gap-2.5', {
            'cursor-pointer': !props.disabled,
          })}
        >
          <div
            className={cx(
              'h-4 w-4 flex items-center justify-center flex-shrink-0 rounded-default border',
              {
                'border-red-300': hasError,
                'border-gray-400': !hasError,
              }
            )}
          >
            {props.checked && (
              <CheckIcon
                className={cx('h-4 w-4', {
                  'text-green': !props.disabled,
                  'text-gray-200': props.disabled,
                })}
              />
            )}
          </div>
          {children}
          <input
            className="absolute opacity-0 h-0 w-0"
            {...props}
            ref={inputRef}
            type="checkbox"
          />
        </label>
      </div>

      {hasError && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <div className="mt-2 text-sm text-red-600">{message}</div>
          )}
        />
      )}
    </div>
  );
};

export default CheckboxField;
