import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { ReactNode } from 'react';
import cx from 'classnames';
import convertToSlug from '../../utils/convert-to-slug';

interface MDXComponentProps {
  children: ReactNode & string;
  type?:
    | 'no-style'
    | 'no-padding'
    | 'testimonials'
    | 'xsmall'
    | 'small'
    | 'default'
    | 'large';
  hasDefaultColor?: boolean;
}

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-has-content */

export const MDXComponent: React.FC<MDXComponentProps> = ({
  children,
  type = 'default',
  hasDefaultColor,
}) => (
  <MDXProvider
    components={{
      h1: (props) => (
        <h2
          {...props}
          id={convertToSlug(props.children)}
          className="body-l text-gray-900 pt-14 pb-8"
        />
      ),
      h2: (props) => (
        <h2
          {...props}
          id={convertToSlug(props.children)}
          className="body-l text-gray-900 py-8"
        />
      ),
      h3: (props) => (
        <h3
          {...props}
          id={convertToSlug(props.children)}
          className="body-m text-gray-900 pt-14 pb-8"
        />
      ),
      p: (props) => (
        <p
          {...props}
          className={cx({
            'pb-6': type === 'no-style',
            '': type === 'no-padding',
            'testimonial-s': type === 'testimonials',
            'body-3xs lg:body-xs pb-6': type === 'xsmall',
            'body-xs pb-6': type === 'small',
            'body-s pb-6': type === 'default',
            'body-m pt-9': type === 'large',
          })}
        />
      ),
      a: (props) => (
        <a
          {...props}
          className={cx('break-words', {
            'text-green': !hasDefaultColor,
            'testimonial-s': type === 'testimonials',
            'body-3xs lg:body-xs': type === 'xsmall',
            'body-xs': type === 'small',
            'body-s': type === 'default',
            'body-m': type === 'large',
          })}
          target="__blank"
          rel="noreferrer"
        />
      ),
      ul: (props) => (
        <ul
          {...props}
          className={cx('list-dot-primary pb-8 pl-6 ', {
            'body-3xs lg:body-xs': type === 'xsmall',
            'body-xs': type === 'small',
            'body-s': type === 'default',
            'body-m': type === 'large',
          })}
        />
      ),
      li: (props) => <li {...props} />,
      q: (props) => (
        <blockquote
          {...props}
          className="mdx-quote border-l-4 border-gray-200 text-lg leading-8 font-medium italic text-gray-900 px-4 py-2 mb-6 body-s"
        />
      ),
      blockquote: (props) => (
        <blockquote
          {...props}
          className="mdx-quote border-l-4 border-gray-200 text-lg leading-8 font-medium italic text-gray-900 px-4 py-2 mb-6 body-s"
        />
      ),
      small: (props) => (
        <small {...props} className="text-sm leading-6 font-normal pb-6" />
      ),
      br: (props) => <br {...props} />,
    }}
  >
    <MDXRenderer>{children}</MDXRenderer>
  </MDXProvider>
);
