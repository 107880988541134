import React from 'react';
import { ReactComponent as Arrow } from '../../../assets/svg/steps-arrow.svg';
import { MDXComponent } from '../../shared/mdx-component';

export const Steps = ({ list }) => (
  <ol className="mt-6 grid grid-cols-1 lg:grid-cols-[1fr_12px_1fr_12px_1fr] gap-3 max-w-52 sm:max-w-screen-xxs md:max-w-md lg:max-w-none mx-auto">
    {list.map((step, stepIdx) => (
      <React.Fragment key={stepIdx}>
        {stepIdx !== 0 ? (
          <li aria-hidden="true" className="flex mx-auto h-3 lg:h-auto">
            <Arrow className="self-center text-green rotate-90 lg:rotate-0" />
          </li>
        ) : null}
        <li className="flex mx-auto lg:mx-0">
          <div className="lg:py-5 flex items-center flex-col lg:flex-row">
            <span className="flex-shrink-0 self-center">
              <span className="w-10 h-10 flex items-center justify-center border-2 border-green rounded-full button-m text-gray-600">
                {stepIdx < 9 ? '0' : ''}
                {stepIdx + 1}
              </span>
            </span>
            <span className="mt-4 lg:mt-0.5 lg:ml-4 steps-s text-gray-500 text-center lg:text-left">
              {step?.text?.data?.childMdx && (
                <MDXComponent type="no-style">
                  {step.text.data.childMdx.body}
                </MDXComponent>
              )}
            </span>
          </div>
        </li>
      </React.Fragment>
    ))}
  </ol>
);
