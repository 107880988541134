import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { ButtonDataProps, ImageProps, MDXProps } from '../../types/shared';
import { Button } from '../button/button';
import { MDXComponent } from '../shared/mdx-component';

export interface RecruitmentProps {
  id?: string;
  items: RecruitmentComponentProps[];
}
interface RecruitmentComponentProps {
  title: string;
  text: MDXProps;
  image: ImageProps;
  button: ButtonDataProps;
}

const RecruitmentItem: React.FC<RecruitmentComponentProps> = ({
  title,
  text,
  image,
  button,
}) => (
  <div className="flex last:mb-16 mt-0 lg:my-16 lg:justify-end w-full">
    <div className="flex relative flex-wrap lg:flex-nowrap lg:justify-end lg:w-5/6">
      <div className="z-1 relative transform rounded-3xl w-full  lg:max-h-full translate-y-1/2 px-4 max-h-[15rem] lg:max-w-[21rem] xl:max-w-[28rem] lg:absolute lg:top-1/2 lg:left-0 lg:-translate-y-1/2 lg:-translate-x-1/2 lg:px-0">
        {image && (
          <GatsbyImage
            className="rounded-3xl min-h-full min-w-full max-h-[15rem] lg:min-h-[25rem] lg:max-h-full"
            image={image.localFile?.childImageSharp?.gatsbyImageData}
            alt={title}
            objectPosition="top"
            objectFit="cover"
          />
        )}
      </div>
      <div className="bg-green pt-[10rem] pb-10 pl-6 pr-6 lg:pl-[14rem] xl:pl-[17rem] lg:pt-[7.5rem] lg:pb-[7.5rem] lg:pr-[6.25rem] lg:rounded-3xl text-gray-50 w-full list-dot-primary-white">
        <h3 className="h400-semibold mb-4">{title}</h3>
        {text?.data?.childMdx && (
          <MDXComponent hasDefaultColor>{text.data.childMdx.body}</MDXComponent>
        )}
        <Button
          href={button.url}
          tagName="a"
          type="secondary-reversed"
          className="button-l px-5 py-3.5 w-full lg:w-auto"
        >
          {button.text}
        </Button>
      </div>
    </div>
  </div>
);

const RecruitmentComponent: React.FC<RecruitmentProps> = ({ id, items }) => (
  <section id={id} className="bg-gray-50">
    <div className="flex flex-wrap max-w-8xl mx-auto lg:px-8">
      {items.map((item) => (
        <RecruitmentItem key={item.title} {...item} />
      ))}
    </div>
  </section>
);

export default RecruitmentComponent;
