import XIcon from '@heroicons/react/outline/esm/XIcon';
import React, { useCallback, useEffect } from 'react';
import { useIsServerClient } from '../../hooks/use-is-server-client';
import { MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';
import cx from 'classnames';
import { useIsMobile } from '../../hooks/use-is-mobile';

const ANNOUNCEMENT_BAR_CLOSED_AT = 'announcement_bar_closed_at';

export interface AnnouncementBarProps {
  text: MDXProps;
  updatedAt: string;
}

const AnnouncementBar: React.FC<AnnouncementBarProps> = ({
  text,
  updatedAt,
}) => {
  const { isClient } = useIsServerClient();
  const popupClosed = React.useMemo(() => {
    if (isClient)
      return window.localStorage.getItem(ANNOUNCEMENT_BAR_CLOSED_AT);
  }, [isClient]);

  const [isOpen, setIsOpen] = React.useState(false);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (
      (!popupClosed && isClient) ||
      new Date(popupClosed) < new Date(updatedAt)
    ) {
      setIsOpen(true);
    }
  }, [popupClosed, updatedAt]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    window.localStorage.setItem(
      ANNOUNCEMENT_BAR_CLOSED_AT,
      new Date().toISOString()
    );
  }, []);

  return (
    Boolean(text?.data?.childMdx?.body) && (
      <div
        className={cx('bg-gray-900 z-[19] ', {
          hidden: !isOpen,
          'sticky bottom-0': isMobile,
          relative: !isMobile,
        })}
      >
        <div className="max-w-8xl md:mx-auto flex p-3 xl:px-0">
          <div className="relative w-full flex gap-2 md:text-center items-center justify-between md:justify-center text-green body-xs md:body-s">
            <MDXComponent type="no-padding">
              {text.data?.childMdx?.body}
            </MDXComponent>

            <XIcon
              className="flex-shrink-0 h-6 w-6 text-gray-300 md:absolute md:right-0 md:top-1/2 md:-translate-y-1/2 cursor-pointer"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default AnnouncementBar;
