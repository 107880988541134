import cx from 'classnames';
import * as React from 'react';
import { BlogIndexContainer } from '../../components/blog-index/blog-index-container';
import Layout from '../../components/layout/layout';
import { AnchorLink } from '../shared/anchor-link';
import {
  getBlogCategoryLocalizations,
  getUrlLocalizations,
} from '../../utils/parse-localization-url';
import { navigate, useIntl } from 'gatsby-plugin-react-intl';

export const BlogIndex = ({ data, location }) => {
  const { pathname } = location;
  const { locale } = useIntl();

  if (!data?.articles || !data?.categories) return null;

  const tabStyle = (category) => ({
    'bg-white text-gray-900':
      pathname.includes(`/${category.pathname}/${category.url}`) ||
      (category === 'news' &&
        (pathname.endsWith(`/${category.pathname}/`) ||
          pathname.endsWith(`/${category.pathname}`))),
    'text-gray-700': !pathname.includes(
      `/${category.pathname}/${category.url}`
    ),
  });

  if (!data.articles.nodes.length) navigate('/');

  return (
    <Layout
      {...data}
      seo={data.page?.seo}
      localizations={
        data.urls
          ? getUrlLocalizations('blog', data.urls.localizations, data.urls)
          : getBlogCategoryLocalizations(
              data.categories.nodes.find((cat) => cat.locale === locale)
            )
      }
    >
      <BlogIndexContainer
        heading={data.page?.heading || {}}
        className="pt-16 lg:pt-24"
        articles={data.articles.nodes}
        withGradient
      >
        <div className="flex justify-center mx-auto gap-2 pt-8 pb-10 lg:pt-5 lg:pb-24 ">
          <div className="group p-0.5 rounded-md flex bg-gray-200 flex-col md:flex-row">
            {data.categories.nodes.map((category) => (
              <AnchorLink
                key={category.url}
                to={`/${category.pathname}/${category.url}`}
                className={cx(
                  'px-9 py-2 border border-gray-200 rounded-md button-m',
                  tabStyle(category),
                  'hover:bg-gray-400 hover:text-white'
                )}
              >
                {category.name}
              </AnchorLink>
            ))}
          </div>
        </div>
      </BlogIndexContainer>
    </Layout>
  );
};

export default BlogIndex;
