import React, { useRef, useState } from 'react';
import { HeroCards, HeroCardsProps } from './hero-cards';
import { Image } from '../shared/image';
import VolumeUpIcon from '@heroicons/react/outline/esm/VolumeUpIcon';
import { HeadingProps } from '../heading/heading';

export interface HeroProps {
  heading: HeadingProps;
  carousel?: HeroCardsProps;
  audio?: { url: string; mime: string };
}

export const Hero: React.FC<HeroProps> = ({ heading, carousel, audio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  return (
    <section className="h-full -mt-[6rem] -z-1">
      <div className="max-w-8xl mx-auto flex flex-col lg:flex-row relative min-h-[34.375rem] xs:min-h-[53.75rem] sm:min-h-[34.375rem] lg:min-h-[53.75rem]">
        <div className="absolute top-24 xs:top-0 -right-[100%] sm:-right-[35%] md:w-full">
          <Image
            src="brain.png"
            alt="brain"
            objectPosition="right"
            objectFit="contain"
            className="ml-auto"
          />
        </div>
        {audio && (
          <audio ref={audioRef}>
            <source src={audio.url} type={audio.mime} />
          </audio>
        )}
        <div className="w-full flex justify-between items-center content-center flex-wrap mt-28 lg:mt-40 md:mb-16">
          <div className="sm:max-w-[26.5625rem] max-w-[19rem] pb-10 px-8">
            <div className="text-gray-900 relative z-2 flex flex-col gap-5">
              <h1 className="text-3.5xl lg:text-7xl font-bold tracking-wider block leading-9 lg:leading-tight pr-8">
                {heading.title}
              </h1>

              {audio && (
                <span className="hidden shadow-green lg:flex bg-white rounded-full p-2 items-center h-10 w-10">
                  <VolumeUpIcon
                    className="h-8 w-8 hover:text-green-secondary cursor-pointer"
                    onClick={() => {
                      if (!isPlaying) {
                        audioRef.current.play();
                      } else {
                        audioRef.current.currentTime = 0;
                        audioRef.current.pause();
                      }
                      setIsPlaying(!isPlaying);
                    }}
                  />
                </span>
              )}
              <div className="text-gray-900  text-sm lg:text-base lg:leading-8 font-normal lg:font-semibold w-[50%] lg:w-full">
                {heading.description}
              </div>
            </div>
          </div>
          <HeroCards {...carousel} />
        </div>
      </div>
    </section>
  );
};
