import useWindowDimensions from './use-window-dimensions';
import { useIsServerClient } from './use-is-server-client';

export const SM_BREAKPOINT = 640;
export const MD_BREAKPOINT = 768;
export const LG_BREAKPOINT = 1024;

export const useIsMobile = () => {
  const { isClient } = useIsServerClient();
  const { width } = useWindowDimensions(isClient);

  return {
    isSmall: width < SM_BREAKPOINT,
    isMedium: width < MD_BREAKPOINT,
    isMobile: width < LG_BREAKPOINT,
    isDesktop: width >= LG_BREAKPOINT,
  };
};
