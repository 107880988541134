import React from 'react';
import { Heading, HeadingProps } from '../heading/heading';
import {
  DiseaseArticleContent,
  DiseaseArticleContentProps,
} from './disease-article-content';
import cx from 'classnames';
import { WhoWeTreat, WhoWeTreatProps } from './who-we-treat';
import convertToSlug from '../../utils/convert-to-slug';

export type DiseaseArticleType = 'image-center' | 'image-right';

export interface DiseaseArticleProps extends DiseaseArticleContentProps {
  id?: string;
  type: DiseaseArticleType;
  headingProps: Omit<HeadingProps, 'title' | 'description'>;
  heading: Pick<HeadingProps, 'title' | 'description'>;
  extras?: WhoWeTreatProps;
}

export const DiseaseArticle: React.FC<DiseaseArticleProps> = ({
  id,
  headingProps,
  heading,
  type,
  extras,
  ...props
}) => (
  <article id={id} className="py-8 lg:py-16 px-6 xl:px-0">
    <Heading
      {...heading}
      {...headingProps}
      className={cx(
        'max-w-8xl mx-auto',
        heading?.description ? 'pb-16 md:pb-21' : 'pb-5'
      )}
      id={convertToSlug(heading?.title as string)}
      position="left"
    />

    <div
      className={cx(
        {
          'max-w-8xl': type === 'image-right',
          'max-w-6xl': type === 'image-center',
        },
        'mx-auto'
      )}
    >
      <DiseaseArticleContent type={type} {...props} />
    </div>

    {extras && <WhoWeTreat {...extras} />}
  </article>
);
