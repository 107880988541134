import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { XBlock, XMasonry } from 'react-xmasonry';
import { ImageProps } from '../../types/shared';
import { ListItem } from '../list-with-tabs/list-with-tabs';
import { useIsMobile } from '../../hooks/use-is-mobile';
import useWindowDimensions from '../../hooks/use-window-dimensions';
import { useIsServerClient } from '../../hooks/use-is-server-client';
import { AnchorLink } from '../shared/anchor-link';
import { ModalDialog } from '../modal/modal';
import { ModalContent } from '../modal/modal-content';
import ArrowNarrowLeftIcon from '@heroicons/react/solid/esm/ArrowNarrowLeftIcon';
import ArrowNarrowRightIcon from '@heroicons/react/solid/esm/ArrowNarrowRightIcon';

export interface GridBlocksProps {
  blockWidth?: number;
  type?: 'default' | 'gallery';
  blocks: {
    width?: number;
    widthMobile?: number;
    height?: number;
    heightMobile?: number;
    alignToBottom?: boolean;
    image?: ImageProps;
    text?: string;
    disease?: {
      pathname: string;
      slug: string;
      excerpt: ListItem;
    };
  }[];
}

export const GridBlocks: React.FC<GridBlocksProps> = ({
  blockWidth = 210,
  blocks,
  type = 'default',
}) => {
  const { isMobile } = useIsMobile();
  const { isClient } = useIsServerClient();
  const { width } = useWindowDimensions(isClient);
  const [blockIndex, setBlockIndex] = useState<number>();

  const isGallery = useMemo(() => type === 'gallery', [type]);

  const imagesIndexes = useMemo(
    () =>
      blocks
        .map((block, index) => ({ ...block, index }))
        .filter((block) => block.image),
    [blocks]
  );

  if (!width) return null;

  return (
    <>
      <XMasonry
        center={false}
        targetBlockWidth={isMobile ? width / 2 - 50 : blockWidth}
        maxColumns={isMobile ? 2 : 6}
      >
        {blocks?.map(
          (
            {
              disease,
              width,
              height,
              widthMobile,
              heightMobile,
              alignToBottom,
              image,
              text,
            },
            j
          ) => {
            const blockHeight = isMobile
              ? heightMobile || height || 1
              : height || 1;

            const blockWidth = isMobile
              ? widthMobile || width || 1
              : width || 1;

            const content = (
              <div
                className={cx(
                  'p-2 relative',
                  alignToBottom
                    ? isGallery
                      ? 'lg-grid:top-[200px]'
                      : 'lg-grid:top-[100px]'
                    : ''
                )}
              >
                <div
                  className={cx(
                    'text-gray-900 text-xs md:text-base font-semibold w-full rounded-5xl transition-colors',
                    {
                      'bg-gray-100 hover:bg-gray-50 flex justify-center items-center text-center hover:text-green':
                        disease?.excerpt,
                      ...(isGallery && !text
                        ? {
                            'h-[200px]': blockHeight === 1,
                            'h-[400px]': blockHeight === 2,
                            'h-[600px]': blockHeight === 3,
                          }
                        : {
                            'h-[100px]': blockHeight === 1,
                            'h-[200px]': blockHeight === 2,
                            'h-[300px]': blockHeight === 3,
                          }),
                      'p-4': !image && disease?.excerpt,
                      'py-4': text,
                      'overflow-hidden cursor-pointer group': image,
                    }
                  )}
                >
                  {image ? (
                    <GatsbyImage
                      className="group-hover:scale-[1.07] transition duration-700 ease-in-out h-full w-full"
                      image={image?.localFile?.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      alt=""
                      onClick={() => setBlockIndex(j)}
                    />
                  ) : (
                    disease?.excerpt?.title || (
                      <span className="h450 lg:h400">{text}</span>
                    )
                  )}
                </div>
              </div>
            );
            return (
              <XBlock width={blockWidth} key={j}>
                {disease ? (
                  <AnchorLink to={`/${disease.pathname}/${disease.slug}`}>
                    {content}
                  </AnchorLink>
                ) : (
                  content
                )}
              </XBlock>
            );
          }
        )}
      </XMasonry>
      {isGallery && (
        <ModalDialog isOpen={!isNaN(blockIndex)}>
          {!isNaN(blockIndex) && (
            <ModalContent
              className="relative"
              size="gallery"
              onClose={() => setBlockIndex(undefined)}
            >
              {blockIndex !== imagesIndexes?.[0].index && (
                <div
                  className="z-1 absolute top-1/2 left-4 lg:left-8 w-8 h-8 lg:w-16 lg:h-16 bg-gray-100 rounded-full flex justify-center items-center cursor-pointer hover-zoom-in"
                  onClick={() => setBlockIndex(blockIndex - 1)}
                >
                  <ArrowNarrowLeftIcon className="w-6 h-6 lg:w-12 lg:h-12 text-gray-400" />
                </div>
              )}
              {blockIndex !==
                imagesIndexes?.[imagesIndexes.length - 1].index && (
                <div
                  className="z-1 absolute top-1/2 right-4 lg:right-8 w-8 h-8 lg:w-16 lg:h-16 bg-gray-100 rounded-full flex justify-center items-center cursor-pointer hover-zoom-in"
                  onClick={() => setBlockIndex(blockIndex + 1)}
                >
                  <ArrowNarrowRightIcon className="w-6 h-6 lg:w-12 lg:h-12 text-gray-400" />
                </div>
              )}
              <div className="h-full rounded-2xl overflow-hidden lg:aspect-h-5 lg:aspect-w-8">
                <div className="translate-z-0 transform">
                  <GatsbyImage
                    className="h-full"
                    image={
                      blocks[blockIndex]?.image?.localFile?.childImageSharp
                        .gatsbyImageData
                    }
                    objectFit="contain"
                    alt=""
                  />
                </div>
              </div>
            </ModalContent>
          )}
        </ModalDialog>
      )}
    </>
  );
};
