import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { ImageProps } from '../../types/shared';
import { Heading, HeadingProps } from '../heading/heading';
import ChevronDoubleDownIcon from '@heroicons/react/solid/ChevronDoubleDownIcon';

export interface ImageSectionProps {
  image: ImageProps;
  heading?: HeadingProps;
  id?: string;
  unwrap?: boolean;
}

export const ImageSection: React.FC<ImageSectionProps> = ({
  image,
  heading,
  id,
  unwrap,
}) => {
  const [unwrapped, setUnwrapped] = useState(false);
  return (
    <section className="py-8 lg:py-16 px-6 xl:px-0" id={id}>
      {heading && (
        <Heading
          {...heading}
          className={cx(
            'max-w-8xl mx-auto',
            heading?.description ? 'pb-16 md:pb-21' : 'pb-5'
          )}
          position={heading?.description ? 'center' : 'left'}
        />
      )}
      {image && (
        <div
          className={cx(
            'translate-z-0 transform relative',
            unwrap
              ? {
                  'transition-[max-height] ease-in duration-200 max-w-4xl  rounded-5xl overflow-hidden mx-auto flex':
                    true,
                  'max-h-[300px]': !unwrapped,
                  'max-h-[1000px]': unwrapped,
                }
              : 'max-w-8xl mx-auto'
          )}
        >
          {unwrap && !unwrapped && (
            <div className="top-0 z-1 absolute h-full w-full  bg-gradient-to-b from-transparent via-transparent via-50% to-gray-50 flex justify-center items-end">
              <ChevronDoubleDownIcon
                className="h-8 w-8 mb-4 text-gray-400 hover:text-green cursor-pointer"
                onClick={() => setUnwrapped(!unwrapped)}
              />
            </div>
          )}
          <GatsbyImage
            className="min-h-full min-w-full"
            image={image?.localFile.childImageSharp.gatsbyImageData}
            objectFit="cover"
            objectPosition="center"
            alt=""
          />
        </div>
      )}
    </section>
  );
};
