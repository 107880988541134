import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { deepFind } from '../../utils/deep-find';
import { Field } from '../field/field';
import { SelectComponent } from './select-component';
import { SelectOptionProps } from './select-option';

export interface SelectProps {
  name: string;
  options: SelectOptionProps[];
  label?: string;
}

export const Select: React.FC<SelectProps> = ({ name, options, label }) => {
  const ctx = useFormContext();
  if (!ctx) {
    console.error('Select must be inside Form Component');
  }
  const {
    control,
    formState: { errors },
  } = ctx;
  const hasError = Boolean(deepFind(errors, name));

  return (
    <Field name={name} label={label} errors={errors}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <SelectComponent
            onChange={onChange}
            options={options}
            value={value}
            hasError={hasError}
          />
        )}
      />
    </Field>
  );
};
