import cx from 'classnames';
import * as React from 'react';
import { BlogIndexContainerProps } from '../../types/blog';
import { Heading } from '../heading/heading';
import { BlogIndexElement } from './blog-index-element';

export const BlogIndexContainer: React.FC<
  {
    className?: string;
  } & BlogIndexContainerProps
> = ({ children, className, heading, articles, withGradient }) => {
  return (
    <div
      className={cx(
        className,
        {
          'bg-gradient-to-b from-white to-gray-900 via-gray-200': withGradient,
        },
        'pb-20 px-6 lg:pb-28 lg:px-8'
      )}
    >
      <div className="relative max-w-8xl mx-auto">
        <Heading
          tag="p"
          title={heading.title}
          description={heading.description}
        />

        {children}

        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {articles.map((article) => (
            <BlogIndexElement key={article.title} {...article} />
          ))}
        </div>
      </div>
    </div>
  );
};
