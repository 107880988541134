import axios from 'axios';
import { navigate, useIntl } from 'gatsby-plugin-react-intl';

interface PayUPaymentData {
  order: {
    totalAmount: number | string; // value in pennies 123.43zł => 12343
    currencyCode: string;
    patient: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
    products: {
      name: string;
      unitPrice: number | string;
      quantity: number;
    }[];
  };
}

export const usePayU = () => {
  const { locale } = useIntl();

  const createPayment = ({ order }: PayUPaymentData, callback) => {
    axios
      .post(`${process.env.GATSBY_API_URL}/payU`, {
        order,
        language: locale,
      })
      .then(({ data }) => {
        window.location.assign(data.data.redirectUri);
        callback();
      })
      .catch((err) => {
        console.error(err.message);
        navigate('/payment-callback?status=error');

        callback();
      });
  };

  return { createPayment };
};
