import React from 'react';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Linkedin } from '../../assets/svg/linkedin.svg';
import { ReactComponent as Logo } from '../../assets/svg/logo-small.svg';
import { ReactComponent as ZnanyLekarz } from '../../assets/svg/znanylekarz.svg';
import { useTranslationsContextData } from '../../hooks/use-context-data';
import { SocialLinksProps } from '../../types/shared';
import LanguageSelect from '../language-select/language-select';
import { FooterColumn, FooterColumnProps } from './footer-column';
import { FooterContact, FooterContactProps } from './footer-contact';
export interface FooterProps {
  slogan: string;
  items: FooterColumnProps[];
  contact: FooterContactProps[];
  additional: FooterColumnProps[];
  socialLinks: SocialLinksProps;
}

export const Footer: React.FC<FooterProps> = ({
  slogan,
  contact,
  items,
  additional,
  socialLinks,
}) => {
  const translations = useTranslationsContextData();

  return (
    <footer className="bg-gray-900 text-gray-300 px-10">
      <div className="max-w-8xl md:mx-auto pt-16 pb-10 md:pt-20 md:pb-16">
        <div className="flex flex-col md:flex-row md:gap-10 lg:gap-20">
          <div className="max-w-full w-81">
            <Logo className="w-full" />
            <p className="footer-xl mt-6.25 mb-16">{slogan}</p>

            {contact.map((item) => (
              <FooterContact key={item.label} {...item} />
            ))}

            <div className="flex gap-7">
              {socialLinks?.facebook && (
                <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
                  <Facebook className="fill-gray-300 hover:fill-green" />
                </a>
              )}
              {socialLinks?.linkedin && (
                <a href={socialLinks.linkedin} target="_blank" rel="noreferrer">
                  <Linkedin className="fill-gray-300 hover:fill-green" />
                </a>
              )}
              {socialLinks?.znanyLekarz && (
                <a
                  href={socialLinks.znanyLekarz}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ZnanyLekarz className="fill-gray-300 hover:fill-green" />
                </a>
              )}
            </div>

            <div className="flex flex-col w-full mt-7 gap-7">
              {additional.map((column) => (
                <FooterColumn key={column.name} {...column} />
              ))}
            </div>
          </div>
          <div className="flex-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 md:gap-x-10 mt-16 md:mt-0">
            {items.map((column) => (
              <FooterColumn key={column.name} {...column} />
            ))}
          </div>
        </div>
        <p className="form-label text-gray-400 text-center pt-8 mt-16 border-t border-gray-200">
          {`© ${new Date().getFullYear()} `}
          {translations['footer.copyrights']}
        </p>
      </div>
    </footer>
  );
};
export default Footer;
