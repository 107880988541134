import { useState, useEffect } from 'react';

function getWindowDimensions(isClient?: boolean) {
  return isClient
    ? {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    : {};
}

export default function useWindowDimensions(isClient?: boolean) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(isClient)
  );

  useEffect(() => {
    if (!isClient) return;

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions(isClient));
    };

    if (isClient && !windowDimensions.width) handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]);

  return windowDimensions;
}
