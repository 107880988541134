import React from 'react';
import Layout from '../../components/layout/layout';
import { CTA } from '../../components/cta/cta';
import { Stats } from '../../components/stats/stats';
import { graphql } from 'gatsby';
import { DiseaseArticle } from '../../components/diseases-articles/disease-article';
import FAQ from '../../components/faq/faq';
import Cards from '../../components/cards/cards';
import { VideoSection } from '../../components/video-section/video-section';
import { ImageSection } from '../../components/image-section/image-section';
import { Heading } from '../../components/heading/heading';
import HowCanWeHelp from '../../components/how-can-we-help/how-can-we-help';
import ExampleTherapy from '../../components/therapy/example-therapy';
import WhoWeTreat from '../../components/diseases-articles/who-we-treat';
import { Team } from '../../components/team/team';
import {
  PathnameSlugLocalization,
  getPathnameSlugLocalizations,
} from '../../utils/parse-localization-url';
import TableOfContents from '../../components/table-of-contents/table-of-contents';
import { getTableOfContents } from '../../utils/get-table-of-contents';

export const Disease = ({ data }) => {
  if (!data.disease) return null;

  const { heading, content, seo, teamMembers, tableOfContents } = data.disease;

  return (
    <Layout<PathnameSlugLocalization>
      {...data}
      seo={seo}
      localizations={getPathnameSlugLocalizations(data.disease)}
    >
      <section className="px-6 lg:px-8">
        <Heading
          className="max-w-8xl mx-auto pt-16 md:pt-24 pb-16"
          {...heading}
        />
      </section>
      
      {tableOfContents && content && (
        <div
          className="px-6 xl:px-0 max-w-8xl mx-auto"
        >
          <TableOfContents content={getTableOfContents(content)} />
        </div>
      )}

      {content &&
        content.map(
          (
            {
              elementId,
              cta,
              faq,
              stats,
              cards,
              content,
              image,
              video,
              exampleTherapy,
              pointsList,
              extras,
            },
            key
          ) => (
            <React.Fragment key={key}>
              {content && (
                <DiseaseArticle
                  id={elementId}
                  type="image-right"
                  headingProps={{
                    tag: 'h2',
                  }}
                  {...content}
                />
              )}
              {video && <VideoSection {...video} id={elementId} />}
              {image && <ImageSection {...image} id={elementId} />}
              {cards && <Cards {...cards} id={elementId} />}
              {cta && (
                <CTA
                  className="bg-gray-100"
                  {...cta}
                  id={elementId}
                  button={{
                    ...cta.button,
                    positonRight: true,
                  }}
                />
              )}
              {stats && <Stats {...stats} id={elementId} />}
              {faq && <FAQ className="bg-white" {...faq} id={elementId} />}
              {exampleTherapy && (
                <ExampleTherapy {...exampleTherapy} id={elementId} />
              )}
              {pointsList && <HowCanWeHelp {...pointsList} id={elementId} />}
              {extras && (
                <section
                  id={elementId}
                  className="py-8 lg:py-16 px-6 xl:px-0 max-w-8xl mx-auto"
                >
                  <WhoWeTreat {...extras} />
                </section>
              )}
            </React.Fragment>
          )
        )}
      {teamMembers && <Team {...teamMembers} />}
    </Layout>
  );
};

export const query = graphql`
  query DiseasePage($language: String, $slug: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    disease: strapiDisease(locale: { eq: $language }, slug: { eq: $slug }) {
      locale
      pathname
      slug
      localizations {
        data {
          attributes {
            locale
            pathname
            slug
          }
        }
      }
      seo {
        ...SeoFragment
      }
      heading {
        ...HeadingAllFragment
      }
      teamMembers {
        ...TeamMembersFragment
      }
      tableOfContents
      content {
        elementId
        cta {
          ...CTAFragment
        }
        stats {
          ...StatsFragment
        }
        faq {
          ...FAQFragment
        }
        content {
          heading {
            ...HeadingAllFragment
          }
          content {
            data {
              childMdx {
                body
                tableOfContents
              }
            }
          }
          caption
          image {
            ...ImageFragment
          }
          youtubeUrlHash
        }
        cards {
          heading {
            ...HeadingTitleDescriptionFragment
          }
          cards {
            ...CardFragment
          }
        }
        video {
          ...VideoFragment
        }
        image {
          heading {
            ...HeadingTitleDescriptionFragment
          }
          image {
            ...ImageFragment
          }
        }
        exampleTherapy {
          title
          image {
            ...ImageFragment
          }
          text {
            data {
              childMdx {
                body
              }
            }
          }
          link {
            ...ButtonFragment
          }
          items {
            subtitle
            description {
              data {
                childMdx {
                  body
                }
              }
            }
            stats {
              label
              value
            }
          }
          youtubeUrlHash
        }
        pointsList {
          heading {
            ...HeadingAllFragment
          }
          columnLeft {
            ...HowCanWeHelpColumnFragment
          }
          columnRight {
            ...HowCanWeHelpColumnFragment
          }
        }
        extras {
          title
          text {
            data {
              childMdx {
                body
              }
            }
          }
        }
      }
    }
  }
`;
export default Disease;
