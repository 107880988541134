import * as React from 'react';
import Layout from '../../components/layout/layout';
import BlogEntryComponent from '../../components/blog-entry/blog-entry-component';
import { BlogIndexContainer } from '../../components/blog-index/blog-index-container';
import { graphql } from 'gatsby';
import { getPathnameSlugLocalizations } from '../../utils/parse-localization-url';

export const BlogEntry = ({ data }) => {
  if (!data.article) return null;

  return (
    <Layout
      {...data}
      seo={data.article?.seo}
      localizations={getPathnameSlugLocalizations(data.article)}
    >
      <div className="pt-16 max-w-8xl mx-auto">
        <BlogEntryComponent {...data.article} />
        <BlogIndexContainer
          className="pt-5 lg:pt-16"
          heading={data.page.heading}
          articles={data.lastArticles.nodes}
        />
      </div>
    </Layout>
  );
};

export default BlogEntry;

export const query = graphql`
  query BlogEntry($language: String, $slug: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    article: strapiBlog(locale: { eq: $language }, slug: { eq: $slug }) {
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      ...BlogEntryFragment
      content {
        data {
          childMdx {
            body
            tableOfContents
          }
        }
      }
      tableOfContents
      seo {
        ...SeoFragment
      }
    }
    page: strapiBlogPage(locale: { eq: $language }) {
      heading {
        ...HeadingTitleDescriptionFragment
      }
    }
    lastArticles: allStrapiBlog(
      sort: { order: DESC, fields: date }
      filter: { locale: { eq: $language }, slug: { ne: $slug } }
      limit: 3
    ) {
      nodes {
        ...BlogEntryFragment
      }
    }
  }
`;
