import { Dialog, Transition } from '@headlessui/react';
import React, { Context, Fragment, useState } from 'react';
import { ModalFormType } from '../../types/modal';
import { Button, ButtonProps } from '../button/button';

export interface ModalProps {
  buttonText: string;
  buttonProps?: ButtonProps;
}

export interface ModalContextProps {
  openModal?: () => void;
  closeModal?: () => void;
}

export const ModalContext: Context<ModalContextProps> = React.createContext({});

export const ModalDialog: React.FC<{
  isOpen: boolean;
}> = ({ isOpen, children }) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      static
      as="div"
      className="fixed inset-0 z-20 overflow-y-auto"
      onClose={() => undefined}
    >
      <div className="min-h-screen text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <Transition.Child
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {children}
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
);

const Modal: React.FC<ModalProps> = ({ buttonProps, buttonText, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (e) => {
    if (buttonProps?.onClick) {
      buttonProps.onClick(e);
    }
    setIsOpen(true);
  };

  return (
    <ModalContext.Provider value={{ closeModal }}>
      <Button {...buttonProps} onClick={openModal}>
        {buttonText}
      </Button>
      <ModalDialog isOpen={isOpen}>{children}</ModalDialog>
    </ModalContext.Provider>
  );
};

export default Modal;
