import ArrowNarrowRightIcon from '@heroicons/react/solid/esm/ArrowNarrowRightIcon';
import VideoCameraIcon from '@heroicons/react/solid/esm/VideoCameraIcon';
import React, { useState } from 'react';
import { ButtonDataProps, MDXProps } from '../../types/shared';
import { Heading } from '../heading/heading';
import { AnchorLink } from '../shared/anchor-link';
import { MDXComponent } from '../shared/mdx-component';
import TestimonialComponent, {
  TestimonialComponentProps,
} from './testimonial-component';
import { ModalDialog } from '../modal/modal';
import { ModalVideo } from '../modal/modal-types/modal-video';
import convertToSlug from '../../utils/convert-to-slug';

export interface ExampleTherapyProps extends TestimonialComponentProps {
  title: string;
  link: ButtonDataProps;
  items: TherapyItemProps[];
  id?: string;
  youtubeUrlHash?: string;
}

export interface TherapyItemProps {
  subtitle: string;
  description: MDXProps;
  stats: { value: string; label: string }[];
}

const TherapyItem: React.FC<TherapyItemProps> = ({
  subtitle,
  description,
  stats,
}) => (
  <div className="my-10">
    <div className="text-gray-500 label-s pb-3">
      <h3>{subtitle}</h3>
    </div>
    <blockquote className="text-gray-500 body-xs mb-10">
      {description?.data?.childMdx && (
        <MDXComponent>{description.data.childMdx.body}</MDXComponent>
      )}
    </blockquote>
    <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
      {stats.map((stat) => (
        <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
          <dt className="text-base text-gray-500 leading-6 font-medium">
            {stat.label}
          </dt>
          <dd className="text-6xl leading-9 font-extrabold tracking-tight">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  </div>
);

export const ExampleTherapy: React.FC<ExampleTherapyProps> = ({
  title,
  link,
  items,
  id,
  youtubeUrlHash,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <section id={id}>
      <div className="max-w-8xl mx-auto py-16 px-6 lg:pt-28 lg:px-8 grid lg:grid-cols-2 gap-16 lg:gap-24">
        <TestimonialComponent {...props} />
        <div>
          <div className="flex gap-4 items-center">
            <Heading
              position="left"
              title={title}
              tag="h2"
              id={convertToSlug(title as string)}
            />
            {youtubeUrlHash && (
              <VideoCameraIcon
                className="h-8 w-8 hover:text-green-secondary cursor-pointer"
                onClick={() => setIsOpen(true)}
              />
            )}
          </div>
          {items.map((item, i) => (
            <TherapyItem key={i} {...item} />
          ))}
          <div className="pt-6 inline-block">
            <AnchorLink
              to={link.url}
              className="button-m text-green hover:text-green-secondary"
            >
              {link.text}
            </AnchorLink>
            <ArrowNarrowRightIcon className="h-5 text-green ml-2 inline-block" />
          </div>
        </div>
      </div>
      {youtubeUrlHash && (
        <ModalDialog isOpen={isOpen}>
          {isOpen && (
            <ModalVideo
              onClose={() => setIsOpen(false)}
              youtubeUrlHash={youtubeUrlHash}
              title={title}
            />
          )}
        </ModalDialog>
      )}
    </section>
  );
};

export default ExampleTherapy;
