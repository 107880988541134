import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import FormData from 'form-data';
import { default as React, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  useModalContextData,
  useTranslationsContextData,
} from '../../../../hooks/use-context-data';
import { ModalFormType, ModalInfoType } from '../../../../types/modal';
import { ModalDialog } from '../../modal';
import { ModalInfo } from '../modal-info';
import { RadioGroup } from '../../../radio-group/radio-group';
import { Textarea } from '../../../textarea/textarea';
import { UploadFiles } from '../../../upload-files/upload-files';
import { ModalContext } from '../../modal';
import { ModalContent } from '../../modal-content';

export interface TherapyFormProps {
  type: ModalFormType;
  externalCloseModal?: () => void;
  commonFormFields?: JSX.Element;
  commonFieldsDefaultValues?: { [key: string]: string | object };
  commonFieldsSchema: yup.AnyObjectSchema;
  setIsLoading: (value: boolean) => void;
}

export const TherapyForm: React.FC<TherapyFormProps> = ({
  type,
  externalCloseModal,
  commonFormFields,
  commonFieldsSchema,
  commonFieldsDefaultValues,
  children,
  setIsLoading,
}) => {
  const { closeModal } = React.useContext(ModalContext);
  const modalData = useModalContextData('form', type);
  const translations = useTranslationsContextData();

  const [modalInfoType, setModalInfoType] = useState<ModalInfoType>(null);
  const requiredError = translations['form.required-field-error'];

  const form = useForm({
    defaultValues: {
      ...commonFieldsDefaultValues,
      pharmacologicalTreatment: '',
      hospitalization: '',
      epilepticEpisode: '',
      cochlearImplant: '',
      neurosurgicalOperation: '',
    },
    resolver: yupResolver(
      commonFieldsSchema.shape({
        pharmacologicalTreatment: yup.string().required(requiredError),
        hospitalization: yup.string().required(requiredError),
        epilepticEpisode: yup.string().required(requiredError),
        cochlearImplant: yup.string().required(requiredError),
        neurosurgicalOperation: yup.string().required(requiredError),
        bloodClottingDisorders: yup.string().required(requiredError),
        cortisoneTherapy: yup.string().required(requiredError),
        brainTumor: yup.string().required(requiredError),
      })
    ),
  });

  const onSubmit = async (data) => {
    const labels = {
      title: `${modalData.heading.title} ${modalData.heading.highlight || ''}`,
      name: translations['form.name-label'],
      age: translations['form.age-label'],
      email: translations['form.email-label'],
      phoneNumber: translations['form.number-label'],
      contactHour: translations['form.phone-contact-hour'],
      symptoms: translations['form.symptoms-label'],
      pharmacologicalTreatment:
        translations['form.pharmacological-treatment-label'],
      hospitalization: translations['form.hospitalization'],
      epilepticEpisode: translations['form.epileptic-episode'],
      cochlearImplant: translations['form.cochlear-implant'],
      neurosurgicalOperation: translations['form.neurosurgical-operation'],
      bloodClottingDisorders: translations['form.blood-clotting-disorders'],
      cortisoneTherapy: translations['form.cortisone-therapy'],
      brainTumor: translations['form.brain-tumor'],
    };

    try {
      setIsLoading(true);
      const { attachments, contactHour, ...rest } = data;
      const formData = new FormData();
      formData.append('type', type);
      if (rest.contact === 'phone') {
        formData.append('contactHour', contactHour.value);
      }
      Object.entries(rest).map(([key, value]) => {
        formData.append(key, value);
      });
      Object.entries(labels).map(([key, value]) => {
        formData.append(`label-${key}`, value);
      });
      attachments.map((attachment) => {
        formData.append(`attachments[]`, attachment);
      });
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      await axios.post(
        `${process.env.GATSBY_API_URL}/therapy`,
        formData,
        config
      );
      setModalInfoType('form-success');
    } catch (err) {
      console.error(err);
      setModalInfoType('error');
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = externalCloseModal || closeModal;

  const onClose = () => {
    if (modalInfoType !== 'error') {
      onCancel();
    }
    setModalInfoType(null);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalContent
          className="max-w-8xl mx-auto"
          onClose={externalCloseModal || closeModal}
          {...modalData}
          size="lg"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-9 text-left">
            <div>
              {commonFormFields}
              <UploadFiles
                name="attachments"
                label={translations['form.attachment-label']}
              />
            </div>
            <div>
              <Textarea
                name="symptoms"
                label={translations['form.symptoms-label']}
                placeholder={translations['form.symptoms-placeholder']}
              />
              <Textarea
                name="pharmacologicalTreatment"
                label={translations['form.pharmacological-treatment-label']}
                placeholder={
                  translations['form.pharmacological-treatment-placeholder']
                }
              />
              <RadioGroup
                name="hospitalization"
                label={translations['form.hospitalization']}
                options={[
                  {
                    value: translations['form.i-have-not-been-hospitalized'],
                    id: 'hospitalization-no',
                    label: translations['form.i-have-not-been-hospitalized'],
                  },
                  {
                    value: translations['form.i-have-been-hospitalized'],
                    id: 'hospitalization-yes',
                    label: translations['form.i-have-been-hospitalized'],
                  },
                ]}
              />
              <RadioGroup
                name="epilepticEpisode"
                label={translations['form.epileptic-episode']}
                options={[
                  {
                    value: translations['form.yes'],
                    id: 'epileptic-episode-yes',
                    label: translations['form.yes'],
                  },
                  {
                    value: translations['form.no'],
                    id: 'epileptic-episode-no',
                    label: translations['form.no'],
                  },
                ]}
              />
              <RadioGroup
                name="cochlearImplant"
                label={translations['form.cochlear-implant']}
                options={[
                  {
                    value: translations['form.yes'],
                    id: 'cochlear-implant-yes',
                    label: translations['form.yes'],
                  },
                  {
                    value: translations['form.no'],
                    id: 'cochlear-implant-no',
                    label: translations['form.no'],
                  },
                ]}
              />
              <RadioGroup
                name="neurosurgicalOperation"
                label={translations['form.neurosurgical-operation']}
                options={[
                  {
                    value: translations['form.yes'],
                    id: 'neurosurgical-operation-yes',
                    label: translations['form.yes'],
                  },
                  {
                    value: translations['form.no'],
                    id: 'neurosurgical-operation-no',
                    label: translations['form.no'],
                  },
                ]}
              />
              <RadioGroup
                name="bloodClottingDisorders"
                label={translations['form.blood-clotting-disorders']}
                options={[
                  {
                    value: translations['form.yes'],
                    id: 'blood-clotting-disorders-yes',
                    label: translations['form.yes'],
                  },
                  {
                    value: translations['form.no'],
                    id: 'blood-clotting-disorders-no',
                    label: translations['form.no'],
                  },
                  {
                    value: translations['form.i-dont-know'],
                    id: 'blood-clotting-disorders-dont-know',
                    label: translations['form.i-dont-know'],
                  },
                ]}
              />
              <RadioGroup
                name="cortisoneTherapy"
                label={translations['form.cortisone-therapy']}
                options={[
                  {
                    value: translations['form.yes'],
                    id: 'cortisone-therapy-yes',
                    label: translations['form.yes'],
                  },
                  {
                    value: translations['form.no'],
                    id: 'cortisone-therapy-no',
                    label: translations['form.no'],
                  },
                  {
                    value: translations['form.i-dont-know'],
                    id: 'cortisone-therapy-dont-know',
                    label: translations['form.i-dont-know'],
                  },
                ]}
              />
              <RadioGroup
                name="brainTumor"
                label={translations['form.brain-tumor']}
                options={[
                  {
                    value: translations['form.yes'],
                    id: 'brain-tumor-yes',
                    label: translations['form.yes'],
                  },
                  {
                    value: translations['form.no'],
                    id: 'brain-tumor-no',
                    label: translations['form.no'],
                  },
                  {
                    value: translations['form.i-dont-know'],
                    id: 'brain-tumor-dont-know',
                    label: translations['form.i-dont-know'],
                  },
                ]}
              />
            </div>
          </div>

          {modalInfoType && (
            <ModalDialog isOpen={Boolean(modalInfoType)}>
              <ModalInfo
                type={modalInfoType}
                textPosition="center"
                externalCloseModal={onClose}
              />
            </ModalDialog>
          )}
        </ModalContent>

        {children}
      </form>
    </FormProvider>
  );
};
