import React from 'react';
import { Listbox } from '@headlessui/react';
import CheckIcon from '@heroicons/react/solid/esm/CheckIcon';

import cx from 'classnames';

export interface SelectOptionProps {
  id: string | number;
  value: string;
}

export const SelectOption: React.FC<SelectOptionProps> = ({ id, value }) => (
  <Listbox.Option
    className={({ active }) =>
      cx('cursor-default select-none relative steps-s lg:body-xs py-2 px-9', {
        'text-white bg-green': active,
        'text-gray-700': !active,
      })
    }
    value={id}
  >
    {({ selected }) => (
      <>
        <span
          className={cx('block truncate text-center', {
            'font-semibold': selected,
          })}
        >
          {value}
        </span>
        {selected && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-4">
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </>
    )}
  </Listbox.Option>
);
