import cx from 'classnames';
import React, { HTMLAttributes, HTMLProps } from 'react';
import { AnchorLink } from '../shared/anchor-link';

type Size = 'lg' | 'md' | 'sm' | 'xs';
type TagName = 'a' | 'button';
type Type =
  | 'primary'
  | 'primary-reversed'
  | 'secondary'
  | 'secondary-reversed'
  | 'tertiary'
  | 'outline'
  | 'outline-light'
  | 'outline-dark'
  | 'text';

export interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement | HTMLAnchorElement>, 'size'> {
  tagName?: TagName;
  size?: Size;
  type?: Type;
  full?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
}

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  tagName = 'button',
  size = 'md',
  type = 'primary',
  full,
  htmlType,
  ...props
}) => {
  const outlineClassNames = cx('border-green  text-green ', {
    'border-4 px-4 xs:px-10 py-4': size !== 'sm',
    'border-2': size === 'sm',
    'hover:border-green-secondary hover:text-green-secondary': !props.disabled,
    'opacity-50': props.disabled,
  });

  const textClassNames = cx('px-0 py-0', {
    'steps-s': size === 'sm',
    'button-m': size === 'md',
    'body-xs': size === 'xs',
  });

  const classNames = cx(className, 'text-center rounded-md', {
    'bg-green text-white': type === 'primary',
    'hover:bg-green-secondary': type === 'primary' && !props.disabled,
    'shadow-sm border-2 border-green bg-white text-gray-900':
      type === 'primary-reversed',
    'hover:bg-gray-50': type === 'primary-reversed' && !props.disabled,
    'bg-gray-800 text-white': type === 'secondary',
    'hover:bg-gray-600': type === 'secondary' && !props.disabled,
    'bg-gray-50  text-green': type === 'secondary-reversed',
    'hover:bg-gray-200': type === 'secondary-reversed' && !props.disabled,
    'bg-gray-100 hover:bg-gray-300 text-gray-800': type === 'tertiary',
    'hover:bg-gray-300': type === 'tertiary' && !props.disabled,
    'opacity-50 pointer-events-none': props.disabled,
    [outlineClassNames]: type === 'outline',
    [textClassNames]: type === 'text',

    'bg-white border border-gray-300 hover:bg-gray-50 text-gray-700':
      type === 'outline-light',

    'border-3 border-gray-800 text-gray-800 hover:border-green hover:text-green':
      type === 'outline-dark',

    'w-full': full,
    'px-1 py-1': size === 'xs' && type === 'outline-light',
    'px-3 py-2': size === 'sm',
    'px-3 py-3': type !== 'outline' && size !== 'xs' && size !== 'sm',

    'button-m ': size !== 'lg' && type !== 'text',
    'button-m xs:button-l': size === 'lg',
  });

  if (props.href && props.href[0] === '/') {
    return (
      <AnchorLink
        className={cx(classNames, 'inline-block')}
        to={props.href}
        {...(props as HTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
      </AnchorLink>
    );
  }

  if (tagName === 'a') {
    return (
      <a
        className={cx(classNames, 'inline-block')}
        {...(props as HTMLAttributes<HTMLAnchorElement>)}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={classNames}
      type={htmlType}
      {...(props as HTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
};
