import React from 'react';
import cx from 'classnames';

type Tag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

export interface HeadingProps {
  className?: string;
  titleClassName?: string;
  highlight?: string;
  title: string;
  description?: string | JSX.Element;
  tag?: Tag;
  position?: 'left' | 'center';
  id?: string;
}

export const Heading: React.FC<HeadingProps> = ({
  className,
  titleClassName,
  highlight,
  title,
  description,
  tag = 'h1',
  position = 'center',
  id,
}) => {
  const TagName = React.useMemo(() => tag, [tag]);
  return (
    <div
      className={cx(className, 'flex flex-col', {
        'text-center items-center': position === 'center',
        'text-left items-start': position === 'left',
      })}
    >
      {highlight && (
        <p className="h500 block tracking-wide uppercase text-green py-1">
          {highlight}
        </p>
      )}
      <TagName id={id}>
        <span
          className={cx(
            titleClassName || 'h400',
            'max-w-5xl  mx-auto block text-gray-900'
          )}
        >
          {title}
        </span>
      </TagName>
      {description && (
        <div
          className={cx(
            'mt-5 body-s text-gray-500',
            position === 'center' && 'max-w-66ch'
          )}
        >
          {description}
        </div>
      )}
    </div>
  );
};
