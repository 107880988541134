import format from 'date-fns/esm/format';
import enGB from 'date-fns/locale/en-GB';
import pl from 'date-fns/locale/pl';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useMemo } from 'react';

export const useFormatDate = (full?: boolean) => {
  const { locale } = useIntl();

  const dateFormat = useMemo(() => {
    if (locale === 'en') {
      return { locale: enGB, format: full ? 'MMMM d yyyy' : 'MMM d, yyyy' };
    }

    return { locale: pl, format: full ? 'd MMMM yyyy' : 'd MMM yyyy' };
  }, [locale]);

  const formatDate = (date: string | Date) =>
    format(new Date(date), dateFormat.format, {
      locale: dateFormat.locale,
    });

  return formatDate;
};
