import { graphql } from 'gatsby';
import { navigate } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import { ModalDialog } from '../components/modal/modal';
import { ModalInfo } from '../components/modal/modal-types/modal-info';
import { ModalInfoType } from '../types/modal';
import { parseTranslations } from '../utils/parse-translations';

export const PaymentCallback = ({ data }) => {
  const [status, setStatus] = useState<string>(null);
  const { items } = data.translations;
  const translations = parseTranslations(items);

  const [isOpen, setIsOpen] = React.useState(true);

  const closeModal = () => {
    setIsOpen(false);
    navigate('/');
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const status = urlSearchParams.get('status');
    const err = urlSearchParams.get('error');

    if (status === 'success') {
      setStatus('payment-success');
    } else if (status === 'error' || err === '501') {
      setStatus('error');
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Layout {...data} seo={{ metaTitle: translations['seo.payment-callback'] }}>
      <div className="h-7/10-height" />
      {status && (
        <ModalDialog isOpen={isOpen}>
          <ModalInfo
            externalCloseModal={closeModal}
            type={status as ModalInfoType}
            textPosition="center"
          />
        </ModalDialog>
      )}
    </Layout>
  );
};

export default PaymentCallback;

export const query = graphql`
  query PaymentCallbackPage($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
  }
`;
