import { useLocation } from '@reach/router';
import { useIntl } from 'gatsby-plugin-react-intl';

export const useIsCurrentLocation = () => {
  const { pathname, hash } = useLocation();
  const { locale } = useIntl();

  const isCurrentLocation = (path: string) => {
    if (!path) return false;

    const parsedPathname = hash
      ? pathname + hash
      : pathname.endsWith('/')
      ? pathname.slice(0, pathname.length - 1)
      : pathname;

    const pathWithLang = `/${locale}${path}`;

    return pathWithLang === parsedPathname;
  };

  return {
    isCurrentLocation,
  };
};
