import React, { useMemo } from 'react';
import { Heading, HeadingProps } from '../heading/heading';
import { TestimonialProps } from './testimonial';
import { Testimonial } from './testimonial';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { ButtonProps } from '../button/button';

const LIST_SIZE_MOBILE = 3;
const LIST_SIZE_DESKTOP = 5;

export interface TestimonialsProps {
  id?: string;
  heading: HeadingProps;
  testimonials: TestimonialProps[];
  link?: {
    text: string;
    url?: string;
  };
}

export const Testimonials: React.FC<TestimonialsProps> = ({
  id,
  heading,
  testimonials,
  link,
}) => {
  const { isMobile } = useIsMobile();

  const columns = useMemo(() => {
    const left = [],
      right = [];

    testimonials
      .sort(() => 0.5 - Math.random())
      .slice(0, isMobile ? LIST_SIZE_MOBILE : LIST_SIZE_DESKTOP)
      .forEach((testimonial, i) =>
        i % 2 ? right.push(testimonial) : left.push(testimonial)
      );

    return { left, right };
  }, [testimonials]);

  return (
    <section id={id} className="bg-gray-50">
      <div className="max-w-8xl mx-auto px-6 lg:px-8 py-16 mb-9 flex justify-between items-center">
        <Heading tag="h2" {...heading} position="left" />
        {link && (
          <a
            href={link.url}
            className="hidden lg:block button-xs font-bold text-green hover:text-green-secondary"
            target="_blank"
            rel="noreferrer"
          >
            {link.text}
          </a>
        )}
      </div>
      <div className="max-w-8xl mx-auto px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-21">
        <div className="flex flex-col gap-y-16 justify-center">
          {columns.left.map((testimonial, i) => (
            <Testimonial key={i} {...testimonial} />
          ))}
        </div>
        <div className="flex flex-col gap-y-16 justify-center">
          {columns.right.map((testimonial, i) => (
            <Testimonial key={i} {...testimonial} />
          ))}
        </div>
      </div>
      {link && (
        <div className="flex justify-center pt-12 pb-12 lg:pb-0">
          <a
            href={link.url}
            className="lg:hidden button-xs font-bold text-green hover:text-green-secondary"
            target="_blank"
            rel="noreferrer"
          >
            {link.text}
          </a>
        </div>
      )}
    </section>
  );
};
