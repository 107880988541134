import React from 'react';
import Layout from '../../components/layout/layout';
import { CTA } from '../../components/cta/cta';
import { Stats } from '../../components/stats/stats';
import { graphql } from 'gatsby';
import { DiseaseArticle } from '../../components/diseases-articles/disease-article';
import Cards from '../../components/cards/cards';
import ExampleTherapy from '../../components/therapy/example-therapy';
import HowCanWeHelp from '../../components/how-can-we-help/how-can-we-help';
import FAQ from '../../components/faq/faq';
import { VideoSection } from '../../components/video-section/video-section';
import { getPathnameSlugLocalizations } from '../../utils/parse-localization-url';
import { getTherapyTableOfContents } from '../../utils/get-table-of-contents';
import TableOfContents from '../../components/table-of-contents/table-of-contents';
import { Team } from '../../components/team/team';
import PageHero from '../../components/page-hero/PageHero';

export const Therapy = ({ data }) => {
  if (!data.therapy) return null;

  const {
    content,
    cards,
    exampleTherapy,
    howCanWeHelp,
    content2,
    herocontent,
    cta,
    stats,
    faq,
    seo,
    tableOfContents,
    video,
    teamMembers,
  } = data.therapy;
console.log(herocontent)
  return (
    <Layout
      {...data}
      seo={seo}
      localizations={getPathnameSlugLocalizations(data.therapy)}
    >
      {herocontent && <PageHero {...herocontent} />}

      {tableOfContents && (content || content2) && (
        <div className="px-6 xl:px-0 max-w-8xl mx-auto">
          <TableOfContents
            content={getTherapyTableOfContents(
              content,
              content2,
              cards,
              exampleTherapy,
              howCanWeHelp,
              stats,
              faq,
              teamMembers,
              cta
            )}
          />
        </div>
      )}

      {content &&
        content?.map((item, index) => (
          <DiseaseArticle
            key={`content-${index}`}
            id={item.contentId}
            type="image-right"
            headingProps={{
              tag: index === 0 ? 'h1' : 'h2',
            }}
            {...item}
          />
        ))}
      {cards && <Cards type="index" {...cards} />}
      {exampleTherapy && <ExampleTherapy {...exampleTherapy} />}
      {howCanWeHelp && <HowCanWeHelp {...howCanWeHelp} />}
      {stats && <Stats {...stats} />}
      {content2?.map((item, index) => (
        <DiseaseArticle
          key={`content2-${index}`}
          id={item.contentId}
          type="image-right"
          headingProps={{ tag: 'h2' }}
          {...item}
        />
      ))}
      {faq && <FAQ className="bg-white" {...faq} />}
      {teamMembers && (
        <Team {...teamMembers} className="pt-0 lg:pt-6 pb-6 lg:pb-16" />
      )}
      {cta && (
        <CTA
          className="bg-gray-100"
          {...cta}
          button={{
            ...cta.button,
          }}
        />
      )}
      {video && <VideoSection {...video} />}
    </Layout>
  );
};

export const query = graphql`
  query TherapyPage($language: String, $slug: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    therapy: strapiTherapy(locale: { eq: $language }, slug: { eq: $slug }) {
      locale
      slug
      pathname
      localizations {
        data {
          attributes {
            locale
            slug
            pathname
          }
        }
      }
      seo {
        ...SeoFragment
      }
      tableOfContents
      teamMembers {
        ...TeamMembersFragment
      }
      herocontent {
        heading {
          ...HeadingAllFragment
        }
        ...ContentFragment
      }
      content {
        heading {
          ...HeadingAllFragment
        }
        ...ContentFragment
      }
      content2 {
        heading {
          ...HeadingAllFragment
        }
        ...ContentFragment
      }
      cards {
        id: anchorId
        heading {
          ...HeadingTitleDescriptionFragment
        }
        cards {
          ...CardFragment
        }
      }
      exampleTherapy {
        id: anchorId
        title
        image {
          ...ImageFragment
        }
        text {
          data {
            childMdx {
              body
            }
          }
        }
        link {
          ...ButtonFragment
        }
        items {
          subtitle
          description {
            data {
              childMdx {
                body
              }
            }
          }
          stats {
            label
            value
          }
        }
        youtubeUrlHash
      }
      howCanWeHelp {
        id: anchorId
        heading {
          ...HeadingAllFragment
        }
        columnLeft {
          ...HowCanWeHelpColumnFragment
        }
        columnRight {
          ...HowCanWeHelpColumnFragment
        }
      }
      stats {
        ...StatsFragment
      }
      faq {
        ...FAQFragment
      }
      cta {
        ...CTAFragment
      }
      video {
        ...VideoFragment
      }
    }
  }
`;
export default Therapy;
