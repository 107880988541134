import React from 'react';
import { MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';
import convertToSlug from '../../utils/convert-to-slug';

export interface WhoWeTreatProps {
  text: MDXProps;
  title: string;
}

export const WhoWeTreat: React.FC<WhoWeTreatProps> = ({ title, text }) => (
  <div className="max-w-8xl mx-auto">
    <h2 className="h400 text-gray-900 py-9" id={convertToSlug(title)}>{title}</h2>
    {text?.data?.childMdx && (
      <div
        className="bg-gray-100 px-12 pt-8 pb-2 rounded-xl shadow-lg
    mt-2 body-s text-gray-600 list-dot-primary"
      >
        <MDXComponent>{text.data.childMdx.body}</MDXComponent>
      </div>
    )}
  </div>
);

export default WhoWeTreat;
