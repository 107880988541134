import HeartIcon from '@heroicons/react/solid/esm/HeartIcon';
import ArrowNarrowRightIcon from '@heroicons/react/outline/esm/ArrowNarrowRightIcon';
import ExclamationIcon from '@heroicons/react/solid/esm/ExclamationIcon';
import InformationCircleIcon from '@heroicons/react/solid/esm/InformationCircleIcon';
import ClipboardIcon from '@heroicons/react/outline/esm/ClipboardIcon';

export const getIcon = (icon) => {
  let Icon;

  switch (icon) {
    case 'info':
      Icon = InformationCircleIcon;
      break;
    case 'heart':
      Icon = HeartIcon;
      break;
    case 'clipboard':
      Icon = ClipboardIcon;
      break;
    case 'error':
      Icon = ExclamationIcon;
      break;
    case 'arrow':
      Icon = ArrowNarrowRightIcon;
      break;
    default:
      break;
  }

  return Icon;
};
