import React from 'react';
import { Heading } from '../heading/heading';
import cx from 'classnames';
import { MDXProps } from '../../types/shared';
import { DiseaseArticleContent } from '../diseases-articles/disease-article-content';
import convertToSlug from '../../utils/convert-to-slug';

type PageHeroProps = {
  heading: {
    title: string;
    description?: string;
    highlight?: string;
  };
  content?: MDXProps;
};

function PageHero(props: PageHeroProps) {
  const { heading } = props
  return (
    // <div className="flex flex-col lg:flex-row gap-8 max-w-8xl mx-auto mt-10 px-6">
    //   <div className="flex-auto order-2 lg:order-1 pt-4">
    //     <Heading
    //       title={heading.title}
    //       tag="h1"
    //       description={heading.description}
    //       highlight={heading.highlight}
    //       className={cx(
    //         'max-w-8xl mx-auto',
    //         heading.description ? 'pb-16 md:pb-21' : 'pb-5'
    //       )}
    //       position="left"
    //     />
    //     <div className={cx('max-w-8xl mx-auto')}>
    //       <DiseaseArticleContent
    //         type={'image-right'}
    //         content={content}
    //       />
    //     </div>
    //     {cta && (
    //       <div className="flex justify-center">
    //         <Button
    //           href={cta.url}
    //           type="primary"
    //           className="px-5 py-3.5"
    //           tagName="a"
    //         >
    //           {cta.text}
    //         </Button>
    //       </div>
    //     )}
    //   </div>
    //   <div className="hidden lg:flex w-[45%] flex-none order-1 lg:order-2">
    //     {image && (
    //       <div className={cx('flex w-full translate-z-0 transform')}>
    //         <GatsbyImage
    //           className="min-h-full mx-auto"
    //           image={image?.localFile.childImageSharp.gatsbyImageData}
    //           objectFit="cover"
    //           objectPosition="center"
    //           alt=""
    //         />
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div className="py-8 lg:py-16 px-6 xl:px-0">
      <Heading
        {...heading}
        tag="h1"
        className={cx(
          'max-w-8xl mx-auto',
          heading?.description ? 'pb-16 md:pb-21' : 'pb-5'
        )}
        id={convertToSlug(heading?.title as string)}
        position="left"
      />

      <div className={cx('max-w-8xl mx-auto')}>
        <DiseaseArticleContent type={'image-right'} {...props} />
      </div>
    </div>
  );
}

export default PageHero;
