import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { ImageProps, MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';
import { DiseaseArticleType } from './disease-article';

export interface DiseaseArticleContentProps {
  content?: MDXProps;
  content2?: MDXProps;
  image?: ImageProps;
  imageMobile?: ImageProps;
  youtubeUrlHash?: string;
  caption?: string;
}

export const DiseaseArticleContent: React.FC<
  DiseaseArticleContentProps & { type: DiseaseArticleType }
> = ({
  type,
  content,
  content2,
  image,
  imageMobile,
  caption,
  youtubeUrlHash,
}) => {
  const { locale } = useIntl();
  const isCenter = type === 'image-center';
  const isRight = type === 'image-right';

  const isMobileImg = React.useMemo(() => Boolean(imageMobile), [imageMobile]);

  return (
    <div
      className={cx('flex flex-col', {
        'gap-8 lg:flex-row': isRight,
        'gap-12': isCenter,
      })}
    >
      <div
        className={cx('text-gray-500', {
          'flex-auto order-2 lg:order-1 ': isRight,
        })}
      >
        {content?.data?.childMdx && (
          <MDXComponent>{content.data?.childMdx?.body || ''}</MDXComponent>
        )}
      </div>
      <div
        className={cx({
          'flex-none order-1 lg:order-2': isRight,
          'w-full lg:w-[45%]': youtubeUrlHash,
        })}
      >
        <div
          className={cx(
            'w-full rounded-default overflow-hidden',
            {
              'max-w-xl mx-auto': isRight,
              'h-0 aspect-w-5 aspect-h-3 ': youtubeUrlHash,
            },
            isCenter && {
              'lg:aspect-w-6 lg:aspect-h-3': true,
              'lg:h-0': isMobileImg,
              'h-0 aspect-w-3 aspect-h-4': !isMobileImg,
            }
          )}
        >
          {youtubeUrlHash ? (
            <iframe
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${youtubeUrlHash}?enablejsapi=1&hl=${locale}&modestbranding=1&color=white&iv_load_policy=3`}
              title={caption}
              frameBorder="0"
              allowFullScreen
            />
          ) : (
            <>
              {image && (
                <div
                  className={cx('flex w-full translate-z-0 transform', {
                    'hidden lg:block': isMobileImg,
                  })}
                >
                  <GatsbyImage
                    className="min-h-full mx-auto"
                    image={image?.localFile.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="center"
                    alt=""
                  />
                </div>
              )}

              {isMobileImg && (
                <div className="translate-z-0 transform lg:hidden">
                  <GatsbyImage
                    className="min-h-full min-w-full"
                    image={
                      imageMobile?.localFile.childImageSharp.gatsbyImageData
                    }
                    objectFit="cover"
                    objectPosition="center"
                    alt=""
                  />
                </div>
              )}
            </>
          )}
        </div>
        {caption && (
          <div className="steps-s text-left  text-gray-500 mt-3">
            <span>{caption}</span>
          </div>
        )}
      </div>

      {isCenter && content2?.data?.childMdx && (
        <div className="text-gray-500">
          <MDXComponent>{content2.data?.childMdx?.body || ''}</MDXComponent>
        </div>
      )}
    </div>
  );
};
