import CheckIcon from '@heroicons/react/outline/esm/CheckIcon';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { MDXProps } from '../../types/shared';
import { Heading, HeadingProps } from '../heading/heading';
import { AnchorLink } from '../shared/anchor-link';
import convertToSlug from '../../utils/convert-to-slug';

interface ColumnItem {
  title?: string;
  url?: string;
  list: MDXProps;
}
export interface HowCanWeHelpProps {
  id?: string;
  heading: HeadingProps;
  columnLeft: ColumnItem[];
  columnRight?: ColumnItem[];
}

const HowCanWeHelpItem: React.FC<ColumnItem> = ({ title, url, list }) => (
  <div className="pb-9">
    {title && (
      <div>
        <CheckIcon
          className="absolute h-6 w-6 text-green-500"
          aria-hidden="true"
        />

        <p className="ml-9 label-m text-gray-900">
          {url ? <AnchorLink to={url}>{title}</AnchorLink> : title}
        </p>
      </div>
    )}
    {list?.data?.childMdx && (
      <MDXProvider
        components={{
          ul: (props) => (
            <ul
              {...props}
              className="mt-2 ml-9 body-xs text-gray-500 list-dot-primary"
            />
          ),
          li: (props) => <li className="pl-6" {...props} />,
        }}
      >
        <MDXRenderer>{list.data.childMdx.body}</MDXRenderer>
      </MDXProvider>
    )}
  </div>
);

const HowCanWeHelp: React.FC<HowCanWeHelpProps> = ({
  id,
  heading,
  columnLeft,
  columnRight,
}) => {
  return (
    <section id={id} className="bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-8xl mx-auto py-16 px-4 sm:px-6 lg:py-36 lg:px-8 xl:px-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <Heading
          tag="h2"
          {...heading}
          position="left"
          id={convertToSlug(heading?.title as string)}
        />

        <div className="mt-12 lg:mt-0 lg:col-span-2 grid grid-cols-1 md:grid-cols-2">
          {[columnLeft, ...(columnRight ? [columnRight] : [])].map(
            (column, i) => (
              <div key={i}>
                {column.map((item, idx) => (
                  <HowCanWeHelpItem key={`${item.title}_${idx}`} {...item} />
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default HowCanWeHelp;
