import MailIcon from '@heroicons/react/outline/esm/MailIcon';
import PhoneIcon from '@heroicons/react/outline/esm/PhoneIcon';
import React from 'react';
import { MDXProps } from '../../types/shared';
import { MDXComponent } from '../shared/mdx-component';

export interface ContactItemProps {
  label: string;
  text: MDXProps;
  type: 'phone' | 'email';
}

export const ContactItem: React.FC<ContactItemProps> = ({
  label,
  text,
  type,
}) => (
  <div className="text-center">
    <p className="uppercase text-green menu-links-16 md:h500 md:tracking-wide">
      {label}
    </p>
    <div className="flex gap-2 md:gap-4 items-center">
      {type ? (
        <>
          <span className="flex-none">
            {type === 'phone' ? (
              <PhoneIcon
                className="h-5 w-5 md:h-8 md:w-8 text-gray-900"
                aria-hidden="true"
              />
            ) : (
              <MailIcon
                className="h-5 w-5 md:h-8 md:w-8 text-gray-900"
                aria-hidden="true"
              />
            )}
          </span>
          {text?.data?.childMdx && (
            <a
              href={`${type === 'phone' ? 'tel:' : 'mailto:'}${
                text?.data?.childMdx?.rawBody || ''
              }`}
              className="uppercase md:normal-case h500 md:h400-normal"
            >
              {text?.data?.childMdx?.rawBody || ''}
            </a>
          )}
        </>
      ) : (
        <div className="uppercase md:normal-case h500 md:label-m md:font-semibold">
          {text?.data?.childMdx && (
            <MDXComponent type="no-style">
              {text.data.childMdx.body}
            </MDXComponent>
          )}
        </div>
      )}
    </div>
  </div>
);
