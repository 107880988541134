import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import cx from 'classnames';

export interface FieldProps {
  name: string;
  label?: string;
  errors?: { [x: string]: any };
  className?: string;
  fieldset?: boolean;
}

export const Field: React.FC<FieldProps> = ({
  name,
  label,
  errors,
  className,
  children,
  fieldset,
}) => {
  const ContainerTagName = React.useMemo(
    () => (fieldset ? 'fieldset' : 'div'),
    [fieldset]
  );
  const LabelTagName = React.useMemo(
    () => (fieldset ? 'legend' : 'label'),
    [fieldset]
  );
  return (
    <ContainerTagName className={cx(className, 'mb-4')}>
      {label && (
        <LabelTagName
          {...(fieldset ? {} : { htmlFor: name })}
          className="block form-label mb-1 text-gray-700"
        >
          {label}
        </LabelTagName>
      )}
      {children}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <p className="mt-2 text-sm text-red-600">{message}</p>
        )}
      />
    </ContainerTagName>
  );
};
