import React from 'react';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Linkedin } from '../../assets/svg/linkedin.svg';
import { ReactComponent as ZnanyLekarz } from '../../assets/svg/znanylekarz.svg';
import { SocialLinksProps } from '../../types/shared';
import { ContactItem, ContactItemProps } from './contact-item';

export interface ContactProps {
  items: ContactItemProps[];
  socialLinks: SocialLinksProps;
}

export const Contact: React.FC<ContactProps> = ({ items, socialLinks }) => (
  <div className="flex flex-col items-center gap-8 md:gap-9">
    {items.map((contact) => (
      <ContactItem key={contact.label} {...contact} />
    ))}
    <div className="flex gap-5">
      {socialLinks?.facebook && (
        <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
          <Facebook className="fill-brand-facebook w-9 h-9" />
        </a>
      )}
      {socialLinks?.linkedin && (
        <a href={socialLinks.linkedin} target="_blank" rel="noreferrer">
          <Linkedin className="fill-brand-linkedin w-9 h-9" />
        </a>
      )}
      {socialLinks?.znanyLekarz && (
        <a href={socialLinks.znanyLekarz} target="_blank" rel="noreferrer">
          <ZnanyLekarz className="fill-brand-znany-lekarz w-9 h-9" />
        </a>
      )}
    </div>
  </div>
);
