import cx from 'classnames';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { Heading, HeadingProps } from '../heading/heading';
import Modal from '../modal/modal';
import { ModalForm } from '../modal/modal-types/form/modal-form';
import { AnchorLink } from '../shared/anchor-link';
import { MDXComponent } from '../shared/mdx-component';
import { ImageProps, LightMDXProps } from '../../types/shared';
import { ModalFormType, ModalTypeProps } from '../../types/modal';
import { GatsbyImage } from 'gatsby-plugin-image';
import convertToSlug from '../../utils/convert-to-slug';

export interface CardProps {
  index?: number;
  title: string;
  subtitle?: LightMDXProps;
  text: string;
  link?: {
    url: string;
    text: string;
  };
  image?: ImageProps;
  modalType?: ModalTypeProps;
}

export interface CardsProps {
  id?: string;
  heading: HeadingProps;
  cards: CardProps[];
  type: 'index' | 'location';
}

export const LocationCard: React.FC<CardProps> = ({
  title,
  subtitle,
  text,
  link,
  image,
}) => (
  <div className="relative bg-white rounded-2xl shadow-lg text-gray-600 flex flex-col lg:flex-row">
    {image && (
      <div
        className={cx(
          'lg:order-2 lg:w-64 lg:rounded-2xl rounded-t-2xl overflow-hidden aspect-w-10 aspect-h-11 lg:aspect-none '
        )}
      >
        <div className="translate-z-0 transform lg:h-full">
          <GatsbyImage
            className="min-h-full min-w-full"
            image={image?.localFile.childImageSharp.gatsbyImageData}
            objectFit="cover"
            objectPosition="center"
            alt=""
          />
        </div>
      </div>
    )}
    <div className="w-75 p-6 flex justify-between flex-col">
      <h3 className="h500 lg:label-l uppercase text-gray-900">{title}</h3>
      {subtitle && (
        <MDXComponent type="large">{subtitle?.childMdx?.body}</MDXComponent>
      )}
      <p className="body-s pt-4 text-gray-500">{text}</p>

      {link && (
        <div
          className={cx('pb-4', {
            'pt-20': !image,
            'pt-4': image,
          })}
        >
          <AnchorLink to={link.url} className="label-base text-green">
            {link.text}
          </AnchorLink>
        </div>
      )}
    </div>
  </div>
);

const IndexCard: React.FC<CardProps> = ({
  index,
  title,
  text,
  subtitle,
  modalType,
}) => (
  <div className="relative">
    <div
      className="z-1 absolute left-1/2 -top-6
              flex h-12
              w-12 -translate-x-1/2 items-center
              justify-center rounded-xl
              bg-green text-3xl
              leading-6 text-white
              shadow-lg"
    >
      {index}
    </div>
    <div className="bg-gray-50 px-6 pt-14 pb-7 rounded-2xl text-center h-full w-full">
      <p className="body-m text-gray-900">{title}</p>
      <p className="body-xs pt-5 text-gray-500">
        <MDXProvider
          components={{
            // eslint-disable-next-line react/jsx-no-useless-fragment
            p: (props) => <React.Fragment {...props} />,
          }}
        >
          <MDXRenderer>{subtitle?.childMdx?.body}</MDXRenderer>
        </MDXProvider>{' '}
        {text && modalType && (
          <Modal
            buttonText={text}
            buttonProps={{
              type: 'text',
              className: 'body-xs text-green',
              size: 'xs',
            }}
          >
            <ModalForm type={modalType?.contentType as ModalFormType} />
          </Modal>
        )}
      </p>
    </div>
  </div>
);

const Cards: React.FC<CardsProps> = ({
  id,
  heading: { tag = 'h2', ...heading },
  cards,
  type = 'index',
}) => {
  return (
    <section
      id={id}
      className={cx({
        'bg-gray-50': type === 'location',
      })}
    >
      <div className="mx-auto max-w-8xl py-8 lg:py-16">
        <Heading
          className="px-8"
          tag={tag}
          {...heading}
          id={convertToSlug(heading?.title as string)}
        />

        <div
          className={cx(`mx-auto pt-20 px-12`, {
            'max-w-sm md:max-w-none grid grid-cols-1 gap-20 md:grid-cols-3 md:gap-8':
              type === 'index',
            'flex flex-col md:flex-row gap-9 justify-center items-stretch':
              type === 'location',
          })}
        >
          {cards.map((item, index) =>
            type === 'index' ? (
              <IndexCard key={item.title} {...item} index={index + 1} />
            ) : (
              <LocationCard key={item.title} {...item} />
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Cards;
