import { graphql } from 'gatsby';
import * as React from 'react';
import BlogIndexComponent from '../components/blog-index/blog-index';

export const BlogIndex = (props) => <BlogIndexComponent {...props} />;

export default BlogIndex;

export const query = graphql`
  query BlogIndexQuery($language: String) {
    modals: allStrapiModalType(filter: { locale: { eq: $language } }) {
      nodes {
        ...ModalTypeFragment
      }
    }
    translations: strapiTranslation(locale: { eq: $language }) {
      ...TranslationsFragment
    }
    settings: strapiSetting(locale: { eq: $language }) {
      ...SettingsFragment
    }
    menu: strapiMenu(locale: { eq: $language }) {
      ...MenuFragment
    }
    footer: strapiFooter(locale: { eq: $language }) {
      ...FooterFragment
    }
    socialLinks: strapiSocialLink(locale: { eq: $language }) {
      ...SocialLinksFragment
    }
    contactData: strapiContactData(locale: { eq: $language }) {
      ...ContactDataFragment
    }
    announcementBar: strapiAnnouncementBar(locale: { eq: $language }) {
      ...AnnouncementBarFragment
    }
    urls: strapiUrl(locale: { eq: $language }) {
      ...UrlsFragment
    }
    categories: allStrapiBlogCategory(
      sort: { order: DESC, fields: url }
      filter: { locale: { eq: $language } }
    ) {
      nodes {
        ...BlogCategoryFragment
      }
    }
    page: strapiBlogPage(locale: { eq: $language }) {
      heading {
        ...HeadingTitleDescriptionFragment
      }
    }
    articles: allStrapiBlog(
      sort: { order: DESC, fields: date }
      filter: { locale: { eq: $language } }
    ) {
      nodes {
        ...BlogEntryFragment
      }
    }
  }
`;
