import CheckCircleIcon from '@heroicons/react/solid/esm/CheckCircleIcon';
import { MDXProvider } from '@mdx-js/react';
import { PaymentProps } from '../../../types/payment';
import cx from 'classnames';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { useCallback, useMemo, useState } from 'react';
import { LightMDXProps } from '../../../types/shared';
import { SelectComponent } from '../../select/select-component';
import { MDXComponent } from '../../shared/mdx-component';
import { PricingBox, PricingBoxProps } from './pricing-box';
import { Steps } from './steps';
import { useTranslationsContextData } from '../../../hooks/use-context-data';

type Summary = string | React.ReactElement;

export interface PricingComponentProps extends PricingBoxProps {
  className?: string;
  title: string;
  description?: LightMDXProps;
  summaryTitle?: string;
  summaryList?: Summary[];
  pricingId?: string;
}

export const SummaryList = ({ list }) => (
  <ul className="mt-8 space-y-8 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8">
    {list.map((item, i) => (
      <li key={i} className="flex items-start lg:col-span-1">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green" aria-hidden="true" />
        </div>
        <p className="ml-3 steps-s text-gray-700">
          {item?.text?.data?.childMdx && (
            <MDXProvider
              components={{
                // eslint-disable-next-line react/jsx-no-useless-fragment
                p: (props) => <React.Fragment {...props} />,
              }}
            >
              <MDXRenderer>{item.text.data.childMdx.body}</MDXRenderer>
            </MDXProvider>
          )}
        </p>
      </li>
    ))}
  </ul>
);

export const PricingComponent: React.FC<PricingComponentProps> = ({
  className,
  type = 'default',
  title,
  description,
  summaryTitle,
  summaryList,
  pricingId,
  ...props
}) => {
  const translations = useTranslationsContextData();

  const [chosenPerson, setChosenPerson] = useState<
    undefined | PaymentProps['pricingTeam']['0']
  >();
  const selectOptions = useMemo(
    () =>
      props.pricingTeam.map((elem) => { 
        if (!elem?.teamMember?.id) return null;
          return {
            id: elem.teamMember.id,
            value: elem.teamMember.name,
          };}).filter(Boolean),
    [props.pricingTeam]
  );

  const onTeamMemberChange = useCallback(
    (value) => {
      setChosenPerson(
        props.pricingTeam.find((elem) => elem.teamMember.id === value.id)
      );
    },
    [props.pricingTeam]
  );

  const summaryListElements = React.useMemo(
    () =>
      props.pricingTeam?.length && chosenPerson?.summaryList?.length
        ? chosenPerson.summaryList
        : summaryList,
    [props.pricingTeam, chosenPerson, summaryList]
  );

  return (
    <div
      id={pricingId}
      className={cx(
        className,
        'max-w-8xl mx-auto rounded-2xl shadow-lg overflow-hidden lg:flex',
        {
          'border-3 border-green': type === 'prescription',
        }
      )}
    >
      <div className="flex-1 bg-white px-6 py-8 lg:p-12">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
          <h3 className="label-m text-gray-600">{title}</h3>
          {description?.childMdx && (
            <div className="lg:hidden mt-6 body-s text-gray-500">
              <MDXComponent>{description.childMdx.body}</MDXComponent>
            </div>
          )}
          {props.pricingTeam?.length ? (
            <div className="lg:w-75">
              <SelectComponent
                onChange={onTeamMemberChange}
                options={selectOptions}
                value={
                  !chosenPerson
                    ? undefined
                    : selectOptions.find(
                        (elem) => elem.id === chosenPerson.teamMember.id
                      )
                }
                placeholder={translations['services.choose-specialist']}
              />
            </div>
          ) : null}
        </div>
        {description?.childMdx && (
          <div className="hidden lg:block mt-6 body-s text-gray-500">
            <MDXComponent>{description.childMdx.body}</MDXComponent>
          </div>
        )}
        {summaryListElements?.length > 0 && (
          <div className="mt-8">
            <div className="flex items-center">
              <p className="flex-shrink-0 pr-4 bg-white label-xs lg:label-s uppercase text-green">
                {summaryTitle}
              </p>
              <div className="flex-1 border-t-2 border-gray-200" />
            </div>
            {type === 'prescription' ? (
              <Steps list={summaryListElements} />
            ) : (
              <SummaryList list={summaryListElements} />
            )}
          </div>
        )}
      </div>
      <PricingBox
        type={type}
        chosenMember={chosenPerson}
        paymentProps={{
          title,
          price: props.price,
          pricingTeam: props.pricingTeam,
        }}
        {...props}
      />
    </div>
  );
};
