import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import {
  GatsbyImage,
  IGatsbyImageData,
  GatsbyImageProps,
} from 'gatsby-plugin-image';

export interface Image extends Omit<GatsbyImageProps, 'alt' | 'image'> {
  alt?: string;
  src: string;
  full?: boolean;
}

interface QueryResult {
  images: {
    edges: {
      node: {
        name: string;
        relativePath: string;
        childImageSharp?: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    }[];
  };
}

export const Image: React.FC<Image> = ({
  src,
  objectFit = 'contain',
  full,
  objectPosition,
  ...props
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { internal: { mediaType: { regex: "/image/" } } }
          ) {
            edges {
              node {
                relativePath
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      `}
      render={(data: QueryResult) => {
        const match = data.images.edges.find(
          ({ node }) => src === node.relativePath
        );

        if (!match) return null;

        const { node: { childImageSharp } = {} } = match;

        return (
          <GatsbyImage
            image={childImageSharp.gatsbyImageData}
            objectFit={objectFit}
            objectPosition={objectPosition}
            alt=""
            {...props}
          />
        );
      }}
    />
  );
};

export default Image;
