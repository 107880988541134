import React, { useState } from 'react';
import { Heading, HeadingProps } from '../heading/heading';
import { ImageProps } from '../../types/shared';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ModalDialog } from '../modal/modal';
import { ModalVideo } from '../modal/modal-types/modal-video';

export interface VideoProps {
  heading: HeadingProps;
  image: ImageProps;
  youtubeUrlHash: string;
  onClick: () => void;
}

export interface VideoSectionProps {
  heading?: HeadingProps;
  list: VideoProps[];
  id?: string;
}

export const VideoItem: React.FC<VideoProps> = ({
  image,
  heading,
  onClick,
}) => (
  <div
    className="w-[255px] md:w-[362px] flex-none bg-white relative rounded-2xl overflow-hidden cursor-pointer shadow-lg"
    onClick={onClick}
  >
    <div className="h-[192px] relative">
      <GatsbyImage
        className="w-full h-full"
        image={image?.localFile?.childImageSharp?.gatsbyImageData}
        alt={heading.title}
        objectPosition="center"
        objectFit="cover"
      />
      <div className="bg-green rounded-full w-18 h-18 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-1 flex items-center justify-center">
        <div className="border-l-[20px] border-t-[11px] border-b-[11px] border-l-white border-t-transparent border-b-transparent border-r-0 ml-1.5"></div>
      </div>
    </div>
    <div className="p-6">
      <h3 className="label-s text-gray-800 mb-3">{heading.title}</h3>
      <p className="body-s-auto text-gray-500">{heading.description}</p>
    </div>
  </div>
);

export const VideoSection: React.FC<VideoSectionProps> = ({
  heading,
  list,
  id,
}) => {
  const [selectedVideo, setSelectedVideo] = useState<VideoProps>();
  return (
    <section className="px-8 xl:px-9 py-8 lg:py-16" id={id}>
      <div className="mx-auto max-w-8xl">
        <Heading tag="h2" {...heading} className="pb-16" />

        <div className="flex gap-x-4 md:gap-x-8 md:px-16 px-0 pb-6 overflow-auto">
          {list.map((item, index) => (
            <VideoItem
              key={`video-${index}`}
              {...item}
              onClick={() => setSelectedVideo(item)}
            />
          ))}
        </div>
      </div>
      <ModalDialog isOpen={!!selectedVideo}>
        {selectedVideo && (
          <ModalVideo
            onClose={() => setSelectedVideo(null)}
            youtubeUrlHash={selectedVideo.youtubeUrlHash}
            title={selectedVideo.heading.title}
          />
        )}
      </ModalDialog>
    </section>
  );
};
