import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslationsContextData } from '../../hooks/use-context-data';

export interface SEOProps {
  metaTitle: string;
  metaDescription?: string;
  metaImage?: { localFile?: { url: string } };
}

const SEO: React.FC<SEOProps> = ({ metaTitle, metaDescription, metaImage }) => {
  const { locale } = useIntl();
  const translations = useTranslationsContextData();

  const { site } = useStaticQuery(graphql`
    query SEOQuery {
      site {
        siteMetadata {
          author
          facebookDomain
        }
      }
    }
  `);

  const description = metaDescription || translations['seo.description'];
  const defaultTitle = translations['seo.title'];

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={metaTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage?.localFile.url || '',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'facebook-domain-verification',
          content: site.siteMetadata?.facebookDomain,
        },
      ].concat([])}
    ></Helmet>
  );
};

export default SEO;
