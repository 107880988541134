export interface SlugLocalization {
  locale: string;
  slug: string;
}

export interface PathnameSlugLocalization {
  locale: string;
  slug: string;
  pathname: string;
}

export interface UrlLocalization {
  locale: string;
  aboutUs: string;
  diseases: string;
  contact: string;
  recruitment: string;
  services: string;
  blog: string;
}

export interface BlogCategoryLocalization {
  locale: string;
  pathname: string;
  url: string;
}

export function parseLocalizationUrls<T extends { locale: string }>(
  localizations: { attributes: T }[],
  getLocalizationUrl: (attr: T) => string
) {
  return localizations?.reduce(
    (prev, { attributes }) => ({
      ...prev,
      [attributes.locale]: getLocalizationUrl(attributes),
    }),
    {}
  );
}

export const getSlugLocalizations = (localizations, currentData) => ({
  ...localizations,
  current: {
    locale: currentData.locale,
    slug: currentData.slug,
  },
  getLocalizationUrl: (attr: SlugLocalization) => `/${attr.slug}`,
});

export const getPathnameSlugLocalizations = (data) => ({
  ...data.localizations,
  current: {
    locale: data.locale,
    pathname: data.pathname,
    slug: data.slug,
  },
  getLocalizationUrl: (attr: PathnameSlugLocalization) =>
    `/${attr.pathname}/${attr.slug}`,
});

export const getBlogCategoryLocalizations = (data) => ({
  ...data.localizations,
  current: {
    locale: data.locale,
    pathname: data.pathname,
    url: data.url,
  },
  getLocalizationUrl: (attr: BlogCategoryLocalization) =>
    `/${attr.pathname}/${attr.url}`,
});

export const getUrlLocalizations = (
  key: keyof UrlLocalization,
  localizations,
  currentData
) => ({
  ...localizations,
  current: {
    locale: currentData.locale,
    [key]: currentData[key],
  },
  getLocalizationUrl: (attr: UrlLocalization) => `/${attr[key]}`,
});
