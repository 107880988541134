import React from 'react';
import { MDXProps } from '../../types/shared';
import convertToSlug from '../../utils/convert-to-slug';

interface TableOfContentsProps {
    content?: MDXProps["data"]["childMdx"]["tableOfContents"]["items"];
}

const TableOfContents: React.FC<TableOfContentsProps> = ({ content }) => {


    return (
      <div className="w-full bg-gray-100 p-8 rounded-xl shadow-lg my-4">
        <h3 className="font-medium text-lg text-green uppercase mb-2">
          Spis Treści
        </h3>
        <ol>
          {content?.map((item, index) => (
            <li key={'toc_' + index} className="mb-0.5">
              <a href={'#' + convertToSlug(item.url)} className="font-semibold">
                {item.title}
              </a>
              {item?.items && (
                <ol className="ml-4">
                  {item.items.map((subItem, subIndex) => (
                    <li key={'toc_nested_' + subIndex} className="mb-0.5">
                      <a href={'#' + convertToSlug(subItem.url)}>
                        {subItem.title}
                      </a>
                      {subItem?.items && (
                        <ol className="ml-4">
                          {subItem.items.map((subSubItem, subSubIndex) => {
                            return (
                            <li
                              key={'toc_nested_deep_' + subSubIndex}
                              className="mb-0.5"
                            >
                              <a href={'#' + convertToSlug(subSubItem.url)}>
                              {subSubItem.title}
                              </a>
                            </li>
                          )})}
                        </ol>
                      )}
                    </li>
                  ))}
                </ol>
              )}
            </li>
          ))}
        </ol>
      </div>
    );
}

export default TableOfContents;