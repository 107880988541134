import React from 'react';
import { GridBlocks, GridBlocksProps } from '../grid-blocks/grid-blocks';
import { Heading, HeadingProps } from '../heading/heading';

export interface DiseasesBlockListProps {
  id?: string;
  heading?: HeadingProps;
  section?: {
    heading?: HeadingProps;
    blocks: GridBlocksProps['blocks'];
  }[];
}

export const DiseasesBlockList: React.FC<DiseasesBlockListProps> = ({
  id,
  heading,
  section,
}) => (
  <section id={id}>
    <div className="max-w-8xl mx-auto py-16 px-6 lg:py-28 lg:px-8">
      <Heading tag="h2" className="pb-16" {...heading} />

      <GridBlocksList section={section} />
    </div>
  </section>
);

export const GridBlocksList: React.FC<
  Pick<DiseasesBlockListProps, 'section'>
> = ({ section }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {section?.map((section, i) => (
      <div key={i} className="-mx-2 mb-11 lg:mb-20">
        <h2 className="px-2 text-3xl font-bold text-green-secondary uppercase">
          {section.heading.title}
        </h2>
        {section?.blocks.length > 0 && <GridBlocks blocks={section.blocks} />}
      </div>
    ))}
  </>
);
