import React from 'react';
import { AnchorLink } from '../shared/anchor-link';

export interface FooterColumnProps {
  name: string;
  url: string;
  links: {
    text: string;
    url: string;
    hidden?: boolean;
  }[];
}

export const FooterColumn: React.FC<FooterColumnProps> = ({
  name,
  url,
  links,
}) => (
  <div>
    <p className="footer-m uppercase mb-4 md:mb-6 hover:text-green">
      <AnchorLink to={url}>{name}</AnchorLink>
    </p>
    {links.map(
      ({ url, text, hidden }) =>
        !hidden && (
          <AnchorLink
            key={text}
            to={url}
            className="menu-links-16 mb-4 block hover:text-green"
          >
            {text}
          </AnchorLink>
        )
    )}
  </div>
);
